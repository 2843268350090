import React from 'react';
import { Loader } from '@mantine/core';
import { useTranslate } from '@aclito/shared/hooks';
import { Button } from '@aclito/ui-web/auth';

import { EmailFormProps } from '../../interface';

import { VStack, ValidatedInput, Icon } from '@/components';

const EmailForm: React.FC<EmailFormProps> = ({ isLoading, onSubmit }) => {
  const t = useTranslate();
  return (
    <>
      <VStack>
        <ValidatedInput
          name="username"
          txLabel="auth.email"
          txPlaceholder="auth.email"
          type="email"
        />
      </VStack>

      <Button onClick={onSubmit}>
        {t('auth.pswreq.title')}
        {!isLoading ? (
          <Icon icon="forward" size={24} color="whiteText" />
        ) : (
          <Loader size={24} color="white" />
        )}
      </Button>
    </>
  );
};

export default EmailForm;
