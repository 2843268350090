import { Middleware, AnyAction } from 'redux';
import { isRejectedWithValue, isFulfilled } from '@reduxjs/toolkit';

import { Logger } from '../../util/logger';
import { AxiomLogger } from '../../classes/AxiomLogger';
import { RejectWithValueWrapper } from '../types';

export const loggerMiddleware: Middleware =
  () => (next) => (action: AnyAction) => {
    Logger.redux(`${action.type}`);
    if (isRejectedWithValue(action)) {
      Logger.redux(
        `${JSON.stringify(action.payload)} ${
          (action.meta.arg as any)?.endpointName ?? ''
        }`,
      );
      const payload = action.payload as RejectWithValueWrapper;

      AxiomLogger.getLogger('rejectedThunk').error({
        type: payload.type,
        payload: JSON.stringify(payload.payload),
        action: action.type,
      });
    }
    if (isFulfilled(action)) {
      AxiomLogger.getLogger('fulfilledThunk').info({
        action: action.type,
      });
    }
    next(action);
  };
