import { useAppDispatch } from '@aclito/shared/hooks/useDispatch';
import { commonActions } from '@aclito/shared/redux/slices';
import { LocaleType } from '@aclito/shared/types';
import { useSharedSettings } from '@aclito/shared/features/misc/hooks/useSettings';

import { openInNewTab } from '@/util/openLinkInNewTab';
import { ROUTE_RESERVANTO, ROUTE_SIGN_IN } from '@/util/constants';
import { useNavigate } from '@/hooks/useNavigate';
import { useModal } from '@/hooks/useModal';

export const useSettings = () => {
  const dispatch = useAppDispatch();
  const { deleteUserAccount } = useSharedSettings();
  const { openConfirmModal } = useModal();

  const nav = useNavigate();

  const handleDeleteAccount = () => {
    openConfirmModal({
      txTitle: 'profile.deleteaccount.alert.header',
      confirmProps: { color: 'red' },
      type: 'deleteAccount',
      txLabels: { cancel: 'close', confirm: 'delete' },
      onConfirm: async () => {
        await deleteUserAccount();
        nav(ROUTE_SIGN_IN);
      },
    });
  };

  const handleLocaleChange = (locale: LocaleType) =>
    dispatch(commonActions.updateLocale({ locale }));

  const goToReservanto = () => {
    const url = `${window.location.protocol}//${window.location.host}${ROUTE_RESERVANTO}`;
    const reservantoUrl = `https://api.reservanto.cz/v1/Authorize/GetLongTimeToken?clientId=f74c6437-a5b2-43ec-9f78-10a1afdb64d2&redirectUrl=${url}&rights=Event_r&rights=Merchant_r&rights=FreeSpace_r&rights=Location_r&rights=Segment_r`;
    openInNewTab(reservantoUrl);
  };

  return { handleDeleteAccount, handleLocaleChange, goToReservanto };
};
