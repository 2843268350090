export const APP_NAME = 'ACLITO';

export const RESERVED_USER_NAME = 'unknown';
export const RESERVED_GENDER = 'unknown';
export const RESERVED_AGE = 'unknown';

export const NOTIFICATION_PLACEHOLDER = {
  bodyTranslations: 'unknown',
  titleTranslations: 'unknown',
};

export const INITIAL_DELTA = {
  latitudeDelta: 4,
  longitudeDelta: 5.5,
};

export const INITIAL_LOCATION = {
  latitude: 49.1951,
  longitude: 16.6068,
  lat: 49.1951,
  lng: 16.6068,
  lon: 16.6068,
  ...INITIAL_DELTA,
};

export const M_TO_KM_CONVERSION = 1000;
export const DELETED_FILE = 'DELETED';

export const BLUR_HASH = '';

export const EVENT_QUEUE_SIZE = 5;
export const ALIAS_PREFIX = 'ALIAS';

export const IDENTICON_BACKGROUND = [255, 255, 255, 0] as [
  number,
  number,
  number,
  number,
];

export const PLACE_PROVIDER = 'aclito';
export const APP_NOTIFICATION_PROVIDER = 'aclito';

export const BIG_ICON_SIZE_CLASSIC_ACHIEVEMENT = 48;
export const SMALL_ICON_SIZE_CLASSIC_ACHIEVEMENT = 24;
export const BIG_ICON_SIZE_LEVEL_ACHIEVEMENT = 32;
export const SMALL_ICON_SIZE_LEVEL_ACHIEVEMENT = 16;

export const LAST_ACTIVE = 'lastActive';
export const TERMS_AND_CONDITIONS_SKIP_DATE = 'tacSkipDate';
export const SEEN_MAP = 'seenMap';
export const PREVIOUS_ORGANIZATIONS = 'previousOrganizations';
export const CURRENT_ORGANIZATION = 'currentOrganization';
export const STARTING_LANGUAGE = 'startingLang';
export const CALENDAR_EVENT = 'calendarEvent';
export const SHOW_TUTORIAL = 'showTutorial';
export const LAST_SEEN_CHAT = 'lastSeenChat';
export const UPDATE_SEEN = '0';
export const REMINDER = 'reminder';
export const LOCALE = 'locale';
export const THEME = 'theme';
export const LOCATION_SERVICES = 'locationServices';
export const PRE_SIGN_UP_DUPLICATE_ERROR = 'EmailAlreadyExists';

export const URL_REGEX =
  /(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\\+.~#()?&//=]*)/;
export const PASSWORD_REGEX = /^(?=.*[A-Za-z])(.*[0-9].*)/;
export const PHONE_NUMBER_REGEX = /^\+?\d{6,14}$/;
export const TIME_FORMAT_REGEX =
  /^(?!00:00$)(?:[1-9]|(?:[01]\d|2[0-3]):[0-5]\d)$/;

export const REDUX_RESET = '/reset';
export const SENTRY_DSN =
  'https://c7d7d048250945eb847e133d060ea786@o1161822.ingest.sentry.io/6248343';
export const FIRST_TIME = 'FIRST_TIME';
export const NOT_FIRST_TIME = 'NOT_FIRST_TIME';
export const DEEP_LINK_URL = 'https://app.aclito.com';

export const BREAKING_CHANGES_VERSION = 14;
export const VERSION = '5.0.3';
