import { useSelectObject } from '@aclito/shared/hooks';
import { Divider, LoadingOverlay } from '@mantine/core';
import React from 'react';
import styled from 'styled-components';
import { ProfileInfoForm } from '@aclito/shared/types';

import {
  ValidatedMultiSelect,
  ValidatedSelect,
  ValidatedInput,
  SubmitButton,
  HStack,
  VStack,
  ValidatedTextArea,
  ValidatedDropzone,
  LabeledButton,
} from '@/components';

interface ProfileEditFormProps {
  submit: (values: ProfileInfoForm) => void;
  isLoading: boolean;
  tac: boolean;
  placeholderName?: string;
  placeholderSurname?: string;
  toggle: () => void;
}

const ProfileEditForm: React.FC<ProfileEditFormProps> = ({
  submit,
  isLoading,
  tac,
  placeholderName,
  placeholderSurname,
  toggle,
}) => {
  const {
    ageOptions,
    translatedGender,
    translatedActivities,
    translatedLanguages,
  } = useSelectObject();

  return (
    <Screen>
      <LoadingOverlay visible={isLoading} overlayBlur={2} />
      <FormWrapper>
        <ValidatedInput
          placeholder={placeholderName}
          txLabel="auth.userinfo.name"
          name="name"
        />
        <ValidatedInput
          placeholder={placeholderSurname}
          txLabel="auth.userinfo.surname"
          name="surname"
        />
        <ValidatedSelect
          txLabel="auth.userinfo.age"
          txPlaceholder="auth.userinfo.age"
          name="age"
          options={ageOptions}
        />
        <ValidatedSelect
          name="gender"
          options={translatedGender}
          txLabel="auth.userinfo.gender"
          txPlaceholder="auth.userinfo.gender"
        />
        <ValidatedMultiSelect
          name="activities"
          options={translatedActivities}
          txLabel="auth.userinfo.activities"
          txPlaceholder="auth.userinfo.activities"
        />
        <ValidatedMultiSelect
          name="languages"
          options={translatedLanguages}
          txLabel="auth.userinfo.languages"
          txPlaceholder="auth.userinfo.languages"
        />
        <ValidatedTextArea
          name="description"
          txLabel="auth.userinfo.description"
          txPlaceholder="auth.userinfo.description"
        />

        <HStack m={'auto'} style={{ width: '100%' }}>
          <ValidatedDropzone name="image" />
          <LabeledButton
            onClick={toggle}
            labelTx={'auth.userinfo.location'}
            buttonTx={'auth.userinfo.setLocation'}
          />
        </HStack>

        <Divider m={'sm'} />
        <div style={{ alignSelf: 'center' }}>
          <SubmitButton
            bg="primary"
            tx="continue"
            onSubmit={submit}
            disabled={!tac}
          />
        </div>
      </FormWrapper>
    </Screen>
  );
};

const FormWrapper = styled(VStack)`
  width: 100%;
`;

const Screen = styled.div`
  background-color: ${({ theme }) => theme.background};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;
export default ProfileEditForm;
