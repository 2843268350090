import { useAppSelector, useSelectObject } from '@aclito/shared/hooks';
import { commonSelectors } from '@aclito/shared/redux/slices/commonSlices';
import { Divider, LoadingOverlay } from '@mantine/core';
import React from 'react';
import styled from 'styled-components';
import { profileSelectors } from '@aclito/shared/redux/slices/profileSlices';

import NotificationSettings from './components';
import { useSettings } from './hooks/useSettings';

import {
  SectionGrid,
  VStack,
  TitleBar,
  Select,
  Text,
  ActionButton,
  Title,
} from '@/components';

const Settings: React.FC = () => {
  const { translatedLocaleOptions } = useSelectObject();
  const locale = useAppSelector(commonSelectors.locale);
  const isLoading = useAppSelector(profileSelectors.isLoadingSettings);
  const isDeleteLoading = useAppSelector(
    profileSelectors.isLoadingDeleteAccount,
  );

  const { handleDeleteAccount, handleLocaleChange, goToReservanto } =
    useSettings();

  return (
    <>
      <LoadingOverlay visible={isLoading || isDeleteLoading} overlayBlur={2} />
      <SectionGrid $isOpen={false}>
        <TitleBar>
          <Title tx="nav.settings" />
        </TitleBar>
        <StyledVStack>
          <SubTitle tx="profile.languages" />
          <Select
            style={{ maxWidth: 400 }}
            value={locale}
            onChange={handleLocaleChange}
            options={translatedLocaleOptions}
          />
          <Divider h={24} />
          <SubTitle tx="profile.deleteaccount.alert.header" />
          <ActionButton
            w={200}
            tx="profile.deleteaccount.alert.header"
            onClick={handleDeleteAccount}
            bg="red"
            textcolor={'white'}
          />
          <Divider h={24} />
          <NotificationSettings />
          <Divider h={24} />
          <SubTitle tx={'settings.reservanto.title'} />
          <ReservantoImg
            onClick={goToReservanto}
            src={
              new URL('../../../../images/reservanto.png', import.meta.url).href
            }
          />
        </StyledVStack>
      </SectionGrid>
    </>
  );
};

const ReservantoImg = styled.img`
  display: block;
  max-width: 230px;
  max-height: 95px;
  width: auto;
  height: auto;
  border-radius: 10px;
  padding: 8px;
  background: ${({ theme }) => theme.reservanto};
  :hover {
    cursor: pointer;
  }
`;

const StyledVStack = styled(VStack)``;

const SubTitle = styled(Text)`
  font-size: 20px;
  font-weight: 600;
`;

export default Settings;
