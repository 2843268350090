import * as yup from 'yup';
import moment from 'moment';

import {
  PASSWORD_REGEX,
  PHONE_NUMBER_REGEX,
  RESERVED_USER_NAME,
  TIME_FORMAT_REGEX,
  URL_REGEX,
} from '../util/constants';

import { useTranslate } from './useTranslate';

export const useSchema = () => {
  const t = useTranslate();

  const uuidSchema = yup.string().uuid();

  const signInValidationSchema = yup.object({
    username: yup
      .string()
      .email(t('validation.email.valid'))
      .required(t('validation.email.req')),
    password: yup
      .string()
      .min(8, t('validation.password.valid.length'))
      .required(t('validation.password.req')),
  });

  const userNameValidationSchema = yup.object({
    username: yup
      .string()
      .email(t('validation.email.valid'))
      .required(t('validation.email.req')),
  });

  const passwordValidationSchema = yup.object({
    password: yup
      .string()
      .min(8, t('validation.password.valid.length'))
      .matches(PASSWORD_REGEX, t('validation.password.valid.contains'))
      .required(t('validation.password.req')),
  });

  const passwordConfirmValidationSchema = yup.object({
    confirmPassword: yup
      .string()
      .min(8, t('validation.password.valid.length'))
      .matches(PASSWORD_REGEX, t('validation.password.valid.contains'))
      .required(t('validation.password.req'))
      .oneOf([yup.ref('password'), null], t('validation.confirm.valid')),
  });

  const codeValidationSchema = yup.object({
    code: yup.number().required(t('validation.req')),
  });

  const createFeedSchema = yup.object({
    feedText: yup.string().required(t('validation.req')),
    feedOrg: yup.object().required(t('validation.req')).nullable(),
  });

  const editFeedSchema = yup.object({
    feedText: yup.string().required(t('validation.req')),
    feedOrg: yup.object(),
  });

  const newPlaceSchema = yup
    .object({
      newPlace: yup.string().required(t('validation.req')),
      newPlaceAddress: yup.string().notRequired(),
    })
    .required();

  const newOrgSchema = yup.object({
    name: yup.string().required(t('validation.req')),
    description: yup.string(),
    address: yup.string(),
    maxMembers: yup
      .number()
      .transform((value) => (Number.isNaN(value) ? 1 : value))
      .when('unlimited', {
        is: true,
        then: yup.number().notRequired(),
        otherwise: yup
          .number()
          .min(1, t('validation.min', { min: 1 }))
          .required(t('validation.req'))
          .nullable(),
      })
      .nullable(),
    unlimited: yup.boolean(),
    private: yup.boolean(),
    email: yup.string().email(t('validation.email.valid')),
    telephone: yup
      .string()
      .transform((originalValue) =>
        originalValue === '' ? null : originalValue,
      )
      .matches(PHONE_NUMBER_REGEX, t('validation.telephone.valid'))
      .nullable(),
  });

  const newAvailabilityValidationSchema = yup.object({
    date: yup
      .date()
      .typeError(t('validation.date'))
      .min(moment(), t('validation.past'))
      .required(t('validation.req'))
      .nullable(),
    duration: yup
      .string()
      .matches(TIME_FORMAT_REGEX, t('validation.format'))
      .required(t('validation.req'))
      .nullable(),
    numberOfAvailabilities: yup.number().when('repeating', {
      is: true,
      then: yup.number().nullable().required(t('validation.req')),
    }),
    repeatAfter: yup
      .object()
      .when('repeating', {
        is: true,
        then: yup.object().nullable().required(t('validation.req')),
      })
      .nullable(),
  });

  const newEventValidationSchema = yup.object({
    date: yup
      .date()
      .typeError(t('validation.date'))
      .min(moment(), t('validation.past'))
      .required(t('validation.req'))
      .nullable(),
    duration: yup
      .string()
      .matches(TIME_FORMAT_REGEX, t('validation.format'))
      .required(t('validation.req'))
      .nullable(),
    forFree: yup.boolean(),
    unlimited: yup.boolean(),
    activity: yup.object().required(t('validation.req')).nullable(),
    price: yup
      .number()
      .transform((value) => (Number.isNaN(value) ? 0 : value))
      .when('forFree', {
        is: true,
        then: yup.number().notRequired(),
        otherwise: yup
          .number()
          .min(0, t('validation.min', { min: 0 }))
          .max(1000, t('validation.max', { max: 1000 }))
          .required(t('validation.req'))
          .nullable(),
      })
      .nullable(),
    players: yup
      .number()
      .transform((value) => (Number.isNaN(value) ? 2 : value))
      .when('unlimited', {
        is: true,
        then: yup.number().notRequired(),
        otherwise: yup
          .number()
          .min(2, t('validation.min', { min: 2 }))
          .max(99, t('validation.max', { max: 99 }))
          .required(t('validation.req'))
          .nullable(),
      })
      .when('enableQueue', {
        is: true,
        then: yup
          .number()
          .min(2, t('validation.min', { min: 2 }))
          .required(t('validation.req')),
      })
      .nullable(),
    numberOfEvents: yup.number().when('switchRepeating', {
      is: true,
      then: yup
        .number()
        .transform((value) => (Number.isNaN(value) ? 1 : value))
        .max(180, t('validation.max', { max: 180 }))
        .nullable()
        .required(t('validation.req')),
    }),
    place: yup.object().required(t('validation.req')).nullable(),
    level: yup.object().required(t('validation.req')).nullable(),
    info: yup.string(),
    gender: yup.object().required(t('validation.req')).nullable(),
    name: yup.string().required(t('validation.req')),
    linkRequired: yup.boolean(),
    org: yup.object().nullable(),
    externalLink: yup.string().when('linkRequired', {
      is: true,
      then: yup
        .string()
        .required(t('validation.req'))
        .matches(URL_REGEX, t('validation.url')),
    }),
    repeatAfter: yup
      .object()
      .when('switchRepeating', {
        is: true,
        then: yup.object().nullable().required(t('validation.req')),
      })
      .nullable(),
    hoursConfirm: yup
      .number()
      .when('requireConfirm', {
        is: true,
        then: yup
          .number()
          .required(t('validation.req'))
          .test({
            name: 'is-before-now',
            exclusive: false,
            params: {},
            message: () => t('validation.isInPast'),
            test: function (value) {
              return (
                moment() < moment(this.parent.date).subtract(value, 'hours')
              );
            },
          }),
      })
      .nullable(),
  });
  const editEventValidationSchema = (
    playersCount: number,
    requireTrainer?: boolean,
  ) =>
    yup.object({
      ...(requireTrainer && {
        trainer: yup
          .object()
          .notRequired()
          .when('dontParticipate', {
            is: true,
            then: yup.object().required(t('validation.req')).nullable(),
            otherwise: yup.object().notRequired().nullable(),
          }),
      }),
      date: yup
        .date()
        .typeError(t('validation.date'))
        .min(moment(), t('validation.past'))
        .required(t('validation.req'))
        .nullable(),
      duration: yup
        .string()
        .matches(TIME_FORMAT_REGEX, t('validation.format'))
        .required(t('validation.req'))
        .nullable(),
      forFree: yup.boolean(),
      unlimited: yup.boolean(),
      activity: yup.object().required(t('validation.req')).nullable(),
      price: yup
        .number()
        .transform((value) => (Number.isNaN(value) ? 0 : value))
        .when('forFree', {
          is: true,
          then: yup.number().notRequired(),
          otherwise: yup
            .number()
            .max(1000, t('validation.max', { max: 1000 }))
            .min(0, t('validation.min', { min: 0 }))
            .required(t('validation.req'))
            .nullable(),
        })
        .nullable(),
      players: yup
        .number()
        .transform((value) => (Number.isNaN(value) ? playersCount : value))
        .when('unlimited', {
          is: true,
          then: yup.number().notRequired(),
          otherwise: yup
            .number()
            .min(
              playersCount > 2 ? playersCount : 2,
              t('validation.min', { min: playersCount > 2 ? playersCount : 2 }),
            )
            .max(99, t('validation.max', { max: 99 }))
            .required(t('validation.req'))
            .nullable(),
        })
        .when('enableQueue', {
          is: true,
          then: yup
            .number()
            .min(playersCount, t('validation.min', { min: playersCount }))
            .required(t('validation.req')),
        })
        .nullable(),
      numberOfEvents: yup.number().when('switchRepeating', {
        is: true,
        then: yup.number().nullable().required(t('validation.req')),
      }),
      place: yup.object().required(t('validation.req')).nullable(),
      level: yup.object().required(t('validation.req')).nullable(),
      info: yup.string(),
      gender: yup.object().required(t('validation.req')).nullable(),
      name: yup.string().required(t('validation.req')),
      linkRequired: yup.boolean(),
      org: yup.object().nullable(),
      externalLink: yup.string().when('linkRequired', {
        is: true,
        then: yup
          .string()
          .required(t('validation.req'))
          .matches(URL_REGEX, t('validation.url')),
      }),
      repeatAfter: yup
        .object()
        .when('switchRepeating', {
          is: true,
          then: yup.object().nullable().required(t('validation.req')),
        })
        .nullable(),
      hoursConfirm: yup
        .number()
        .when('requireConfirm', {
          is: true,
          then: yup
            .number()
            .required(t('validation.req'))
            .test({
              name: 'is-before-now',
              exclusive: false,
              params: {},
              message: () => t('validation.isInPast'),
              test: function (value) {
                return (
                  moment() < moment(this.parent.date).subtract(value, 'hours')
                );
              },
            }),
        })
        .nullable(),
    });

  const userInfoValidationSchema = yup.object({
    activities: yup.array().nullable(),
    languages: yup.array().nullable(),
    age: yup.object().nullable(),
    name: yup
      .string()
      .notOneOf([RESERVED_USER_NAME], t('validation.unknown.name')),
    surname: yup
      .string()
      .notOneOf([RESERVED_USER_NAME], t('validation.unknown.surname')),
    gender: yup.object().nullable(),
    image: yup.string(),
    description: yup.string(),
  });

  const reportUserValidationSchema = yup.object({
    message: yup.string().required(t('validation.req')),
  });
  const kickUserValidationSchema = yup.object().shape(
    {
      area: yup.object().when('otherText', {
        is: (otherText: string) => !otherText || otherText.length === 0,
        then: yup.object().required(t('validation.req')),
      }),
      otherText: yup.string().when('area', {
        is: (area: string) => !area || area.length === 0,
        then: yup.string().required(t('validation.req')),
      }),
    },
    [['area', 'otherText']],
  );

  const aliasesEditValidationSchema = yup.object({
    name: yup
      .string()
      .required(t('validation.req'))
      .notOneOf([RESERVED_USER_NAME], t('validation.unknown.name')),
    surname: yup
      .string()
      .required(t('validation.req'))
      .notOneOf([RESERVED_USER_NAME], t('validation.unknown.surname')),
    image: yup.string(),
  });

  const emailValidator = yup.string().email(t('validation.email.valid'));

  const phoneValidator = yup
    .string()
    .matches(PHONE_NUMBER_REGEX, t('validation.telephone.valid'));

  return {
    ValidationError: yup.ValidationError,
    reportUserValidationSchema,
    phoneValidator,
    emailValidator,
    signInValidationSchema,
    newEventValidationSchema,
    editEventValidationSchema,
    passwordConfirmValidationSchema,
    userNameValidationSchema,
    passwordValidationSchema,
    codeValidationSchema,
    userInfoValidationSchema,
    newPlaceSchema,
    newOrgSchema,
    kickUserValidationSchema,
    newAvailabilityValidationSchema,
    aliasesEditValidationSchema,
    uuidSchema,
    editFeedSchema,
    createFeedSchema,
  };
};
