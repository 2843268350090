import { QRCode } from 'react-qrcode-logo';
import styled, { useTheme } from 'styled-components';
import { useEffect, useRef, useState } from 'react';
import { useTranslate } from '@aclito/shared/hooks/useTranslate';

import { ActionButton, HStack, OrgAvatar, VStack } from '@/components';
import { AclitoLogoWhite, PosterTexture } from '@/images';
import withRouter from '@/navigation/withRouter';
import { TypedLocation } from '@/hooks/useLocation';
import { StateOrganizationEdit } from '@/util/types';

import './Poster.css';
import { useWebShare } from '@/hooks/useWebShare';

type OrganizationPosterProps = TypedLocation<StateOrganizationEdit>;

const PX_TO_MM = 0.2645833333;
const MM_TO_PX = 3.7795275591;

const OrganizationPoster: React.FC<OrganizationPosterProps> = ({ state }) => {
  const theme = useTheme();
  const t = useTranslate();
  const qrRef = useRef<QRCode>(null);

  const refTop = useRef<HTMLDivElement>(null);
  const refBottom = useRef<HTMLDivElement>(null);

  const [qrSize, setQrSize] = useState(0);
  const [whiteBoxSize, setWhiteBoxSize] = useState(0);

  const { getUrl } = useWebShare();

  // NOTE: Design is 72 DPI, Print is 300 DPI
  // All on-screen values are set to 300 DPI for correct printing conditions, but are displayed scaled down using the "zoom" function

  useEffect(() => {
    const calculateSizes = () => {
      const topHeight = refTop.current?.clientHeight || 0;
      const bottomHeight = refBottom.current?.clientHeight || 0;

      const qrSizeCalculated =
        MM_TO_PX *
        (_pixelsToMmNumber(842) -
          PX_TO_MM * topHeight -
          PX_TO_MM * bottomHeight -
          _pixelsToMmNumber(160) -
          _pixelsToMmNumber(148));

      const whiteBoxSizeCalculated =
        _pixelsToMmNumber(842) -
        _pixelsToMmNumber(80) -
        PX_TO_MM * topHeight -
        (PX_TO_MM * qrSizeCalculated) / 2 -
        _pixelsToMmNumber(48) -
        _pixelsToMmNumber(50);

      setQrSize(qrSizeCalculated);
      setWhiteBoxSize(whiteBoxSizeCalculated);
    };

    const resizeObserver = new ResizeObserver(() => {
      calculateSizes();
    });

    if (refTop.current) resizeObserver.observe(refTop.current);
    if (refBottom.current) resizeObserver.observe(refBottom.current);

    calculateSizes();

    return () => resizeObserver.disconnect();
  }, []);

  return (
    <Box>
      <Poster align="center" spacing={0} pixelsToMmString={_pixelsToMmString}>
        <Texture pixelsToMmString={_pixelsToMmString} />
        <div ref={refTop}>
          <VStack align="center" spacing={_pixelsToMmString(16)}>
            <HStack noWrap spacing={_pixelsToMmString(4)}>
              <AclitoLogoWhite
                style={{ height: _pixelsToMmString(32), width: '100%' }}
              />
              <Aclito pixelsToMmString={_pixelsToMmString}>Aclito</Aclito>
            </HStack>

            <VStack spacing={_pixelsToMmString(4)}>
              <HStack
                spacing={0}
                style={{ justifyContent: 'center', padding: '0 20px' }}
              >
                <Title pixelsToMmString={_pixelsToMmString}>
                  {t('qr.posterPrint.title')}
                  <TitleYellow pixelsToMmString={_pixelsToMmString}>
                    {t('qr.posterPrint.titleYellow')}
                  </TitleYellow>
                </Title>
              </HStack>
            </VStack>
          </VStack>
        </div>
        <WhiteBox pixelsToMmString={_pixelsToMmString} height={whiteBoxSize} />
        <QRBox pixelsToMmString={_pixelsToMmString}>
          <QRCode
            ref={qrRef}
            quietZone={16}
            size={qrSize}
            style={{
              borderRadius: 10,
            }}
            value={getUrl(state?.org.id ?? '', 'org')}
          />
        </QRBox>
        <div ref={refBottom} style={{ zIndex: 0 }}>
          <OrganizationInfo
            pixelsToMmString={_pixelsToMmString}
            align="center"
            noWrap
          >
            {state && (
              <OrgAvatar
                org={state?.org}
                style={{
                  height: _pixelsToMmString(64),
                  width: _pixelsToMmString(64),
                }}
              />
            )}

            <VStack spacing={_pixelsToMmString(4)}>
              <OrgTitle pixelsToMmString={_pixelsToMmString}>
                {state?.org.name}
              </OrgTitle>
              <OrgDescription pixelsToMmString={_pixelsToMmString}>
                {t('qr.posterPrint.text')}
              </OrgDescription>
            </VStack>
          </OrganizationInfo>
        </div>
      </Poster>
      <PrintButton
        iconType={'print'}
        w={'595px'}
        tx="qr.posterPrint.print.button"
        onClick={() => window.print()}
        color={theme.primary10}
      />
    </Box>
  );
};

const _pixelsToMmNumber = (pixels: number, dpi = 72) => (pixels * 25.4) / dpi;
const _pixelsToMmString = (pixels: number, dpi = 72): string =>
  `${_pixelsToMmNumber(pixels, dpi)}mm`;

const Box = styled(VStack)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 48px;

  @media print {
    padding: 0;
  }
`;

const Poster = styled(VStack)<{
  pixelsToMmString: (pixels: number) => string;
}>`
  position: relative;
  background-color: ${({ theme }) => theme.primary} !important;
  width: 210mm;
  height: 297mm;
  padding: ${({ pixelsToMmString }) => pixelsToMmString(80)} 0;
  overflow: hidden;
  zoom: 75%;

  @media print {
    zoom: 100%;
  }
`;

const Texture = styled(PosterTexture)<{
  pixelsToMmString: (pixels: number) => string;
}>`
  position: absolute;
  top: ${({ pixelsToMmString }) => pixelsToMmString(-80)};
  left: ${({ pixelsToMmString }) => pixelsToMmString(-40)};
  width: 170%;
  height: 170%;
  z-index: 0;
  opacity: 10%;
`;

const WhiteBox = styled.div<{
  height: number;
  pixelsToMmString: (pixels: number) => string;
}>`
  position: absolute;
  background-color: white;
  width: 210mm;
  height: ${({ height }) => height}mm;
  bottom: 0px;
`;

const QRBox = styled.div<{
  pixelsToMmString: (pixels: number) => string;
}>`
  display: flex;
  overflow: hidden;

  margin: 0 ${({ pixelsToMmString }) => pixelsToMmString(80)};
  margin-top: ${({ pixelsToMmString }) => pixelsToMmString(48)};
  border: ${({ pixelsToMmString }) => pixelsToMmString(10)} solid white;
  border-radius: ${({ pixelsToMmString }) => pixelsToMmString(20)};
  padding: ${({ pixelsToMmString }) => pixelsToMmString(24)};
  --s: ${({ pixelsToMmString }) => pixelsToMmString(100)};

  mask: conic-gradient(at var(--s) var(--s), #0000 75%, #000 0) 0 0 /
      calc(100% - var(--s)) calc(100% - var(--s)),
    linear-gradient(#000 0 0) content-box;
`;

const Title = styled.span<{
  pixelsToMmString: (pixels: number) => string;
}>`
  color: ${({ theme }) => theme.whiteText};
  text-align: center;
  font-family: 'Poppins', sans-serif;
  font-size: ${({ pixelsToMmString }) => pixelsToMmString(64)};
  line-height: ${({ pixelsToMmString }) => pixelsToMmString(72)};
  font-style: italic;
  font-weight: 900;
  text-transform: uppercase;
  z-index: 9999;
`;

const TitleYellow = styled.span<{
  pixelsToMmString: (pixels: number) => string;
}>`
  color: ${({ theme }) => theme.yellow};
  text-align: center;
  font-family: 'Poppins', sans-serif;
  font-size: ${({ pixelsToMmString }) => pixelsToMmString(64)};
  line-height: ${({ pixelsToMmString }) => pixelsToMmString(72)};
  font-style: italic;
  font-weight: 900;
  text-transform: uppercase;
  z-index: 9999;
`;

/*
const Subtitle = styled.div<{
  pixelsToMmString: (pixels: number) => string;
}>`
  color: ${({ theme }) => theme.whiteText};
  text-align: center;
  font-family: 'Poppins', sans-serif;
  font-size: ${({ pixelsToMmString }) => pixelsToMmString(32)};
  line-height: ${({ pixelsToMmString }) => pixelsToMmString(48)};
  font-style: normal;
  font-weight: 700;
  z-index: 9999;
`;
*/

const Aclito = styled.div<{
  pixelsToMmString: (pixels: number) => string;
}>`
  color: ${({ theme }) => theme.whiteText};
  font-family: Acme;
  font-size: ${({ pixelsToMmString }) => pixelsToMmString(24)};
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
`;

const OrganizationInfo = styled(HStack)<{
  pixelsToMmString: (pixels: number) => string;
}>`
  padding: 0 ${({ pixelsToMmString }) => pixelsToMmString(80)};
`;

const OrgTitle = styled.div<{
  pixelsToMmString: (pixels: number) => string;
}>`
  color: #333333;
  font-family: 'Poppins', sans-serif;
  font-size: ${({ pixelsToMmString }) => pixelsToMmString(20)};
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

const OrgDescription = styled.div<{
  pixelsToMmString: (pixels: number) => string;
}>`
  color: #333333;
  font-family: 'Poppins', sans-serif;
  font-size: ${({ pixelsToMmString }) => pixelsToMmString(14)};
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

const PrintButton = styled(ActionButton)`
  @media print {
    display: none;
  }
`;

export default withRouter(OrganizationPoster);
