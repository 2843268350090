import React from 'react';
import { useAppSelector } from '@aclito/shared/hooks/useSelector';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useRequestNewPassword } from '@aclito/shared/features/auth/hooks/useRequestNewPassword';
import { authSelectors } from '@aclito/shared/redux/slices/authSlices';
import { PasswordRequestForm } from '@aclito/shared/types';
import { Screen } from '@aclito/ui-web/auth/Screen';
import { Title, Header, Box } from '@aclito/ui-web/auth';

import Footer from '../../components/Footer/Footer';

import { EmailForm } from './components';

import { Container } from '@/components';
import { ROUTE_SUBMIT_PASSWORD } from '@/util/constants';
import { useNavigate } from '@/hooks/useNavigate';

const RequestNewPassword: React.FC = () => {
  const isLoading = useAppSelector(authSelectors.isLoadingRequestPassword);
  const navigate = useNavigate();

  const { initialValues, userNameValidationSchema, requestNewPassword } =
    useRequestNewPassword();

  const handleSubmit = async (values: PasswordRequestForm) => {
    const res = await requestNewPassword(values);
    if (!res.payload) {
      navigate(ROUTE_SUBMIT_PASSWORD);
    }
  };

  const methods = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(userNameValidationSchema),
  });

  return (
    <Box>
      <Header />
      <Container>
        <Screen>
          <Title tx="auth.signin.reset" />
          <FormProvider {...methods}>
            <EmailForm
              isLoading={isLoading}
              onSubmit={methods.handleSubmit(handleSubmit)}
            />
          </FormProvider>
        </Screen>
      </Container>
      <Footer />
    </Box>
  );
};

export default RequestNewPassword;
