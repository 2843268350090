import { Feed } from '@aclito/entities';
import { ImageValueShape } from '@aclito/shared/types';
import React from 'react';
import styled from 'styled-components';
import { useAppSelector, useTranslateDate } from '@aclito/shared/hooks';
import { canEditFeed } from '@aclito/shared/features/organization/utils/canEditFeed';
import { profileSelectors } from '@aclito/shared/redux/slices/profileSlices';

import FeedMenuOptions from './FeedMenuOptions';

import {
  HStack,
  Text,
  VStack,
  Image,
  OrgAvatar,
  PlainCard,
} from '@/components';
import { ROUTE_FEED_INFO } from '@/util/constants';
import { useNavigate } from '@/hooks/useNavigate';

interface FeedCardProps {
  feed: Feed;
}

const FeedCard: React.FC<FeedCardProps> = ({ feed }) => {
  const nav = useNavigate();
  const tDate = useTranslateDate();

  const userInfo = useAppSelector(profileSelectors.userInfo);

  const canEdit = canEditFeed(userInfo.id, feed);

  return (
    <PlainCard
      onClick={() => nav(ROUTE_FEED_INFO, { state: { feedId: feed.id } })}
      style={{ height: '100%', maxWidth: 600 }}
    >
      <VStack>
        <HStack style={{ flexWrap: 'nowrap' }}>
          <HStack style={{ justifyContent: 'space-between', width: '100%' }}>
            <HStack>
              <OrgAvatar org={feed.org} style={{ width: 48 }} />
              <VStack spacing={2}>
                <NameText size={18} lineClamp={1} weight={500}>
                  {feed.org.name}
                </NameText>
                <DateText size="xs">{`${feed.postedBy.name} ${feed.postedBy.surname}`}</DateText>
                <DateText>
                  {tDate(feed.createdAt, 'date.day.month.year')}
                </DateText>
              </VStack>
            </HStack>
            {canEdit && (
              <HStack onClick={(e) => e.stopPropagation()}>
                <FeedMenuOptions feed={feed} compact={true} />
              </HStack>
            )}
          </HStack>
        </HStack>
        <FeedText lineClamp={17}>{feed.text}</FeedText>
        {feed.image?.trim().length ? (
          <Image
            image={feed.image as ImageValueShape}
            timeStamp={feed.updatedAt}
          />
        ) : null}
      </VStack>
    </PlainCard>
  );
};

const DateText = styled(Text)`
  color: ${({ theme }) => theme.grey30};
  font-weight: 400;
  line-height: normal;
  font-size: 12px;
`;

const NameText = styled(Text)`
  line-height: normal;
`;

const FeedText = styled(Text)`
  padding-left: 4px;
  color: ${({ theme }) => theme.grey50};
  font-size: 14px;
  line-height: normal;
  font-weight: 400;
`;

export default FeedCard;
