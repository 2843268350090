import React from 'react';
import { useLocalNotifications } from '@aclito/shared/features/notification/hooks/useLocalNotifications';
import { useAppSelector, useTranslate } from '@aclito/shared/hooks';
import {
  notificationActions,
  notificationSelectors,
} from '@aclito/shared/redux/slices/notificationsSlices';
import { Center, Loader, Stack } from '@mantine/core';
import { openConfirmModal } from '@mantine/modals';
import { useAppDispatch } from '@aclito/shared/hooks/useDispatch';
import { motion, AnimatePresence } from 'framer-motion';
import { dateSort } from '@aclito/shared/util/sorter';
import { useFilterNotifications } from '@aclito/shared/features/notification/hooks/useFilterNotifications';
import { useMediaQuery } from '@mantine/hooks';
import { deviceMax } from '@aclito/ui-web/utils/mediaQueries';

import { Buttons } from './components';
import { useLocalNotificationsWeb } from './hooks/useLocalNotificationsWeb';
import { useNotificationAnimation } from './hooks/useNotificationAnimation';
import NotificationFilter from './components/NotificationFilter';

import { SectionGrid, TitleBar, Title } from '@/components';
import NotificationCard from '@/components/Cards/NotificationCard';
import { useFlexWrap } from '@/hooks/useFlexWrap';

const Notifications: React.FC = () => {
  const t = useTranslate();
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(notificationSelectors.isLoading);

  const {
    filter,
    filteredNotifications,
    handleFilter,
    chipValue,
    handleChipFilter,
    options,
    translations,
  } = useFilterNotifications();

  const { getNotificationBody } = useLocalNotifications();

  const { getOnClick } = useLocalNotificationsWeb();

  const { containerVariants, filterVariants, cardVariant, ref, bounds } =
    useNotificationAnimation(filter);

  const deleteNotifications = () => {
    dispatch(notificationActions.deleteAllNotificationsAsync());
  };

  const handleDelete = () => {
    openConfirmModal({
      confirmProps: { color: 'red' },
      title: t('modal.confirmation'),
      labels: { confirm: t('delete.all'), cancel: t('close') },
      onConfirm: deleteNotifications,
    });
  };

  const sortedNotifications = dateSort(
    filteredNotifications,
    'createdAt',
    'desc',
  );

  const id = 'titleFlexContainer';
  const isFlexWrapped = useFlexWrap(id);
  const queryMobile = useMediaQuery(deviceMax.mobile);

  return (
    <SectionGrid
      $isOpen={false}
      style={{ ...(filter && { paddingBottom: bounds.height + 20 }) }}
    >
      <TitleBar>
        <Title tx="nav.notifications" />
        <Buttons
          {...{
            handleDelete,
            handleFilter,
            compact: isFlexWrapped || queryMobile,
          }}
        />
      </TitleBar>
      {isLoading ? (
        <Center style={{ height: '100%' }}>
          <Loader />
        </Center>
      ) : (
        <MotionContainer
          variants={containerVariants}
          initial="init"
          animate="enter"
        >
          <NotificationFilter
            {...{
              chipValue,
              filter,
              handleChipFilter,
              variant: filterVariants,
              ref,
              options,
              translations,
            }}
          />
          <AnimatePresence>
            {sortedNotifications.map((notification) => {
              return (
                <motion.div {...cardVariant} layout key={notification.id}>
                  <NotificationCard
                    {...{
                      getNotificationBody,
                      notification,
                      getOnClick,
                    }}
                  />
                </motion.div>
              );
            })}
          </AnimatePresence>
        </MotionContainer>
      )}
    </SectionGrid>
  );
};

const MotionVStack = motion(Stack);
const MotionContainer = motion(MotionVStack);
export default Notifications;
