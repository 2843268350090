import {
  centerCrop,
  Crop,
  makeAspectCrop,
  PercentCrop,
  PixelCrop,
} from 'react-image-crop';
import React, { useRef, useState } from 'react';
import { useDisclosure } from '@mantine/hooks';
import blobReduce from 'image-blob-reduce';
import { getRandomAvatarSeed } from '@aclito/shared/util/getRandomUserAttributes';
import { useDebounceEffect } from '@aclito/shared/hooks';

import { canvasPreview } from './canvasPreview';

const aspect = 1;

export const useDropZone = (onChange?: (blob: Blob | string) => void) => {
  const previewCanvasRef = useRef<HTMLCanvasElement>(null);
  const ref = useRef<HTMLInputElement>(null);

  const [imgSrc, setImgSrc] = useState('');
  const imgRef = useRef<HTMLImageElement>(null);
  const [crop, setCrop] = useState<Crop | undefined>(undefined);
  const [completedCrop, setCompletedCrop] = useState<PixelCrop | undefined>(
    undefined,
  );
  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);
  const [preview, setPreview] = useState<Blob | string>();
  const [open, { toggle }] = useDisclosure(false);

  const rotateMarks = [
    { value: -180, label: '-180' },
    { value: 0, label: '0' },
    { value: 180, label: '180' },
  ];

  const scaleMarks = [
    { value: 1, label: '1' },
    { value: 5, label: '5' },
  ];

  const handleUploadClick = () => {
    // @ts-ignore
    ref.current.value = '';
    ref?.current?.click();
  };

  const handleAvatarClick = () => {
    const seed = getRandomAvatarSeed();
    setPreview(seed);
    onChange?.(seed);
  };

  const submit = () => {
    previewCanvasRef?.current?.toBlob?.(async (blob) => {
      if (blob) {
        toggle();

        const newBlob = await finalResize(blob);
        onChange?.(newBlob);
        setPreview(newBlob);
        setRotate(0);
        setScale(1);
      }
    });
  };

  const finalResize = async (inBlob: Blob) => {
    return await blobReduce().toBlob(inBlob, { max: 400 });
  };

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        await canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          scale,
          rotate,
        );
      }
    },
    100,
    [completedCrop, scale, rotate],
  );

  function onSelectFile(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.files && e.target.files.length > 0) {
      setCrop(undefined); // Makes crop preview update between images.
      const reader = new FileReader();
      reader.addEventListener('load', () =>
        setImgSrc(reader.result?.toString() || ''),
      );
      reader.readAsDataURL(e.target.files[0]);
    }
  }

  function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
    const { width, height } = e.currentTarget;
    setCrop(centerAspectCrop(width, height));
  }

  function centerAspectCrop(mediaWidth: number, mediaHeight: number) {
    return centerCrop(
      makeAspectCrop(
        {
          unit: '%',
          width: 90,
        },
        1,
        mediaWidth,
        mediaHeight,
      ),
      mediaWidth,
      mediaHeight,
    );
  }

  const onCompleteCrop = (c: PixelCrop) => {
    setCompletedCrop(c);
  };

  const onChangeCrop = (_: PixelCrop, percentCrop: PercentCrop) => {
    setCrop(percentCrop);
  };

  return {
    onSelectFile,
    imgSrc,
    imgRef,
    aspect,
    onImageLoad,
    onCompleteCrop,
    completedCrop,
    previewCanvasRef,
    onChangeCrop,
    crop,
    scale,
    rotate,
    setRotate,
    setScale,
    preview,
    submit,
    open,
    toggle,
    setPreview,
    generateSeed: handleAvatarClick,
    rotateMarks,
    scaleMarks,
    ref,
    handleUploadClick,
  };
};
