import React from 'react';
import { EmojisKey, UserInfoWithAlias } from '@aclito/shared/types';
import {
  isUserOrganizer,
  isUserTrainer,
} from '@aclito/shared/features/event/utils/getEventRole';
import { EventRaw, FeedbackEvent } from '@aclito/entities';
import { useGetRatings } from '@aclito/shared/features/feedback/hooks/useGetRatings';
import styled, { useTheme } from 'styled-components';
import { config } from '@aclito/shared/config';

import { useNavigate } from '@/hooks/useNavigate';
import {
  IconHolder,
  PlainCard,
  HStack,
  VStack,
  Icon,
  Avatar,
  Text,
} from '@/components';
import { CardStatusBannerText } from '@/common/CardStatusBanner';
import { ROUTE_USER_PROFILE } from '@/util/constants';

interface FeedbackEventsCardProps {
  data: UserInfoWithAlias[];
  event: EventRaw;
  ratings: FeedbackEvent[];
  onCommentClick: (id: string, name: string, text: string | undefined) => void;
  onKarmaClick: (id: string, name: string, karma: boolean) => void;
  onEmojiClickSubmit: (id: string, emoji: EmojisKey | null) => void;
}
interface FeedbackCardProps {
  player: UserInfoWithAlias;
  event: EventRaw;
  onCommentClick: (id: string, name: string, text: string | undefined) => void;
  onKarmaClick: (id: string, name: string, karma: boolean) => void;
  onEmojiClickSubmit: (id: string, emoji: EmojisKey | null) => void;
  ratings: FeedbackEvent[];
}

const FeedbackCards: React.FC<FeedbackEventsCardProps> = ({
  data,
  event,
  ratings,
  onEmojiClickSubmit,
  onKarmaClick,
  onCommentClick,
}) => (
  <>
    {data.map((player) => (
      <FeedbackCard
        key={player.id + player.aliasId}
        player={player}
        event={event}
        ratings={ratings}
        onEmojiClickSubmit={onEmojiClickSubmit}
        onCommentClick={onCommentClick}
        onKarmaClick={onKarmaClick}
      />
    ))}
  </>
);

const FeedbackCard: React.FC<FeedbackCardProps> = ({
  player,
  event,
  ratings,
  onEmojiClickSubmit,
  onKarmaClick,
  onCommentClick,
}) => {
  const nav = useNavigate();
  const theme = useTheme();
  const navigate = () => {
    nav(ROUTE_USER_PROFILE, { state: { userId: player.id } });
  };

  const { emoji, comment, karma, playerId } = useGetRatings(player, ratings);

  const isOrganizer = isUserOrganizer(player, event);
  const isTrainer = isUserTrainer(player, event);
  const canRemoveKarma = !(isOrganizer || isTrainer);

  return (
    <PlainCard
      color={comment || emoji || !!karma ? theme.primary10 : theme.white}
      data-testid={`${player.name} ${player.surname}`}
      onClick={navigate}
    >
      <HStack>
        <Avatar size={48} image={player.image} />
        <VStack spacing={8}>
          <Text weight={700}>{`${player.name} ${player.surname}`}</Text>
          {isUserOrganizer(player, event) && (
            <CardStatusBannerText type={'eventOrganizer'} />
          )}
          {isUserTrainer(player, event) && (
            <CardStatusBannerText type={'eventTrainer'} />
          )}
          {player.aliasId && <CardStatusBannerText type={'alias'} />}
        </VStack>
        <IconHolder spacing={24} style={{ cursor: 'pointer' }}>
          {canRemoveKarma && (
            <StyledIcon
              color={'red'}
              icon={karma ? 'karmaProvided' : 'karmaRemove'}
              size="24"
              onClick={(e) => {
                e.stopPropagation();
                onKarmaClick(
                  playerId,
                  `${player.name} ${player.surname}`,
                  !!karma,
                );
              }}
            />
          )}
          <StyledIcon
            size={24}
            onClick={(e) => {
              e.stopPropagation();
              onCommentClick(
                playerId,
                `${player.name} ${player.surname}`,
                comment,
              );
            }}
            icon={comment ? 'commentCheck' : 'commentAdd'}
          />
          {emoji ? (
            <StyledText
              onClick={(e: any) => {
                e.stopPropagation();
                onEmojiClickSubmit(playerId, emoji);
              }}
              size={'md'}
            >
              {config.emojis[emoji].item}
            </StyledText>
          ) : (
            <StyledIcon
              onClick={(e) => {
                e.stopPropagation();
                onEmojiClickSubmit(playerId, emoji ?? null);
              }}
              icon="emoji"
              size={'24'}
            />
          )}
        </IconHolder>
      </HStack>
    </PlainCard>
  );
};

const StyledIcon = styled(Icon)`
  transition: transform 150ms ease-in-out;
  &:hover {
    transform: scale(1.3);
  }
`;
const StyledText = styled(Text)`
  transition: transform 150ms ease-in-out;
  &:hover {
    transform: scale(1.3);
  }
` as any;

export default FeedbackCards;
