import { SelectObject, TranslateKey } from '../types';
import { sortByItem } from '../util/sorter';
import { config } from '../config';
import {
  translate as selectObjectTranslate,
  getByIdTranslate,
} from '../util/selectObject';

import { useTranslate } from './useTranslate';
const {
  language,
  gender,
  repeat,
  activities,
  kick,
  allLanguages,
  levels,
  age,
  searchPlaces,
} = config;
export const useSelectObject = () => {
  const t = useTranslate();

  const translate = (arr: SelectObject[]) => selectObjectTranslate(arr, t);
  const getById = (id: string, obj: Record<string, SelectObject>) =>
    getByIdTranslate(id, obj, t);

  const translatedGender = Object.values(gender.signup).map((g) => ({
    item: t(g.item as TranslateKey),
    id: g.id,
  }));

  const translatedFilterGender = Object.values(gender.filter).map((g) => ({
    item: t(g.item as TranslateKey),
    id: g.id,
  }));

  const translatedRepeatChoices = Object.values(repeat).map((choice) => ({
    item: t(choice.item as TranslateKey),
    id: choice.id,
  }));

  const translatedActivities = sortByItem(
    Object.values(activities).map((activity) => ({
      item: t(activity.item as TranslateKey),
      id: activity.id,
    })),
  );

  const translatedKickReasonsTypes = Object.values(kick).map((type) => ({
    item: t(type.item as TranslateKey),
    id: type.id,
  }));

  const translatedLanguages = Object.entries(allLanguages).map(
    ([key, lang]) => ({
      item: `${lang.name} (${lang.nativeName})`,
      id: key,
    }),
  );

  const translatedLocaleOptions = Object.values(language).map((lang) => ({
    item: t(lang.item as TranslateKey),
    id: lang.id,
  }));

  const translatedLevels = Object.values(levels).map((level) => ({
    item: t(level.item as TranslateKey),
    id: level.id,
  }));

  const ageOptions = Object.values(age).map((a) => ({
    item: a.id === age.unknown.id ? t(a.item as TranslateKey) : a.item,
    id: a.id,
  }));

  const translatedSearchPlaces = Object.values(searchPlaces).map((type) => ({
    ...type,
    item: t(type.item as TranslateKey),
    id: type.id,
  }));

  return {
    translatedKickReasonsTypes,
    translate,
    translatedGender,
    translatedLanguages,
    translatedActivities,
    translatedFilterGender,
    translatedRepeatChoices,
    translatedLevels,
    ageOptions,
    translatedLocaleOptions,
    translatedSearchPlaces,
    getById,
  };
};
