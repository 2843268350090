import {
  Routes as RDRoutes,
  Route,
  Navigate,
  BrowserRouter,
} from 'react-router-dom';
import { ModalsProvider } from '@mantine/modals';

import { Settings } from '../features/misc/screens/Settings';
import { Profile } from '../features/profile/screens/Profile';
import { Calendar } from '../components/Calendar';
import { ProfileEdit } from '../features/profile/screens/ProfileEdit';
import FirebaseProvider from '../providers/FirebaseProvider';
import EmbeddedCalendar from '../components/EmbeddedCalendar/EmbeddedCalendar';
import DefaultLocation from '../common/DefaultLocation/DefaultLocation';
import { Login } from '../features/auth/screens/Login';
import { Register } from '../features/auth/screens/Register';
import { RequestNewPassword } from '../features/auth/screens/RequestNewPassword';
import { SubmitNewPassword } from '../features/auth/screens/SubmitNewPassword';
import { Availabilities } from '../features/availability/screens/Availabilities';
import { AvailabilityEdit } from '../features/availability/screens/AvailabilityEdit';
import { AvailabilityInfo } from '../features/availability/screens/AvailabilityInfo';
import { AvailabilityNew } from '../features/availability/screens/AvailabilityNew';
import EventEdit from '../features/event/screens/EventEdit/EventEdit';
import EventNew from '../features/event/screens/EventNew/EventNew';
import { Events } from '../features/event/screens/Events';
import { PlayerSuggetions } from '../features/event/screens/PlayerSuggestions';
import { ReportUser } from '../features/misc/screens/ReportUser';
import { ShareItem, ShareItemOld } from '../features/misc/screens/ShareItem';
import { Notifications } from '../features/notification/screens/Notifications';
import OrganizationEdit from '../features/organization/screens/OrganizationEdit/OrganizationEdit';
import OrganizationNew from '../features/organization/screens/OrganizationNew/OrganizationNew';
import Organizations from '../features/organization/screens/Organizations/Organizations';
import { AboutUser } from '../features/profile/screens/AboutUser';
import { UserProfile } from '../features/profile/screens/UserProfile';
import { Search } from '../features/search/screens/Search';
import EventInfo from '../features/event/screens/EventInfo/EventInfo';
import Goals from '../features/goals/Goals';

import { RequireAuth } from './RequireAuth';
import DrawerOutlet from './DrawerOutlet';

import {
  ROUTE_ABOUT_USER,
  ROUTE_EVENTS,
  ROUTE_SEARCH,
  ROUTE_SIGN_IN,
  ROUTE_SIGN_UP,
  ROUTE_REQUEST_PASSWORD,
  ROUTE_SUBMIT_PASSWORD,
  ROUTE_PROFILE,
  ROUTE_CALENDAR,
  ROUTE_SETTINGS,
  ROUTE_NEW_EVENT,
  ROUTE_PROFILE_EDIT,
  ROUTE_EVENT_INFO,
  ROUTE_ORGANIZATIONS,
  ROUTE_NEW_ORGANIZATION,
  ROUTE_AVAILABILITY,
  ROUTE_NEW_AVAILABILITY,
  ROUTE_EVENT_EDIT,
  ROUTE_AVAILABILITY_INFO,
  ROUTE_USER_PROFILE,
  ROUTE_REPORT_USER,
  ROUTE_NOTIFICATIONS,
  ROUTE_PLAYER_SUGGESTIONS,
  ROUTE_AVAILABILITY_EDIT,
  ROUTE_SOCIAL,
  ROUTE_USER_LOCATION,
  ROUTE_EDIT_ORGANIZATION,
  ROUTE_SHARE_ITEM,
  ROUTE_EMBEDDED_CALENDAR,
  ROUTE_RESERVANTO,
  ROUTE_EVENT_INFO_PROVIDERS,
  ROUTE_PROFILE_ALIASES,
  ROUTE_PROFILE_ALIASES_EDIT,
  ROUTE_PROFILE_ALIASES_CREATE,
  ROUTE_NOTES,
  ROUTE_NOTES_USER,
  ROUTE_VERIFICATION,
  ROUTE_INVITE,
  ROUTE_FEED_EDIT,
  ROUTE_FEED_INFO,
  ROUTE_FEEDS,
  ROUTE_MAP,
  ROUTE_GOALS,
  ROUTE_ORGANIZATION_POSTER,
  ROUTE_FEEDBACK,
  ROUTE_ALIAS_INFO,
  ROUTE_STATUS,
} from '@/util/constants';
import { Text, Container } from '@/components';
import Social from '@/features/misc/screens/Social/Social';
import Reservanto from '@/features/misc/screens/Reservanto/Reservanto';
import { EventInfoProviders } from '@/features/event/screens/EventInfoProviders';
import { ProfileAliases } from '@/features/profile/screens/ProfileAliases';
import ProfileAliasesEdit from '@/features/profile/screens/ProfileAliasesEdit/ProfileAliasesEdit';
import ProfileAliasesCreate from '@/features/profile/screens/ProfielAliasesCreate/ProfileAliasesCreate';
import { Notes } from '@/features/notes/screens/Notes';
import NotesUser from '@/features/notes/screens/NotesUser/NotesUser';
import Verification from '@/features/integration/screens/Verification/Verification';
import Invite from '@/features/integration/screens/Invite/Invite';
import OrganizationFeedEdit from '@/features/organization/screens/OrganizationFeedEdit/OrganizationFeedEdit';
import OrganizationFeedInfo from '@/features/organization/screens/OrganizationFeedInfo/OrganizationFeedInfo';
import OrganizationFeed from '@/features/organization/screens/OrganizationFeed/OrganizationFeed';
import ViewMap from '@/features/map/screens/ViewMap/components/ViewMap';
import OrganizationPoster from '@/features/organization/screens/OrganizationPoster/OrganizationPoster';
import Feedback from '@/features/feedback/screens/Feedback/Feedback';
import FeedbackInfo from '@/features/feedback/screens/FeedbackInfo/FeedbackInfo';
import AliasInfo from '@/features/profile/screens/AliasInfo/AliasInfo';
import Status from '@/features/misc/screens/Status/Status';

const Routes = () => {
  return (
    <BrowserRouter>
      <ModalsProvider>
        <RDRoutes>
          <Route path={'/'} element={<Navigate to={ROUTE_SIGN_IN} />} />
          <Route path={ROUTE_SIGN_IN} element={<Login />} />
          <Route
            path={ROUTE_EMBEDDED_CALENDAR}
            element={<EmbeddedCalendar />}
          />
          <Route path={ROUTE_SIGN_IN} element={<Login />} />
          <Route path={ROUTE_SIGN_UP} element={<Register />} />
          <Route
            path={ROUTE_REQUEST_PASSWORD}
            element={<RequestNewPassword />}
          />
          <Route path={ROUTE_SUBMIT_PASSWORD} element={<SubmitNewPassword />} />
          <Route
            path={ROUTE_ORGANIZATION_POSTER}
            element={<OrganizationPoster />}
          />
          <Route
            element={
              <RequireAuth>
                <FirebaseProvider>
                  <DrawerOutlet />
                </FirebaseProvider>
              </RequireAuth>
            }
          >
            <Route path={ROUTE_NOTES} element={<Notes />} />
            <Route path={ROUTE_NOTES_USER} element={<NotesUser />} />
            <Route path={ROUTE_EVENTS} element={<Events />} />
            <Route path={ROUTE_SEARCH} element={<Search />} />
            <Route path={ROUTE_PROFILE} element={<Profile />} />
            <Route path={ROUTE_ALIAS_INFO} element={<AliasInfo />} />

            <Route
              path={`${ROUTE_PROFILE_ALIASES}/:userId`}
              element={<ProfileAliases />}
            />
            <Route path={ROUTE_PROFILE_ALIASES} element={<ProfileAliases />} />
            <Route
              path={ROUTE_PROFILE_ALIASES_EDIT}
              element={<ProfileAliasesEdit />}
            />
            <Route
              path={ROUTE_PROFILE_ALIASES_CREATE}
              element={<ProfileAliasesCreate />}
            />
            <Route path={ROUTE_ORGANIZATIONS} element={<Organizations />} />
            <Route path={ROUTE_USER_PROFILE} element={<UserProfile />} />
            <Route path={ROUTE_SETTINGS} element={<Settings />} />
            <Route path={ROUTE_AVAILABILITY} element={<Availabilities />} />
            <Route
              path={ROUTE_NEW_AVAILABILITY}
              element={<AvailabilityNew />}
            />
            <Route
              path={ROUTE_NEW_ORGANIZATION}
              element={<OrganizationNew />}
            />
            <Route path={ROUTE_FEED_EDIT} element={<OrganizationFeedEdit />} />
            <Route path={ROUTE_FEED_INFO} element={<OrganizationFeedInfo />} />
            <Route path={ROUTE_MAP} element={<ViewMap />} />
            <Route path={ROUTE_FEEDS} element={<OrganizationFeed />} />
            <Route path={`${ROUTE_GOALS}/:userId`} element={<Goals />}></Route>
            <Route path={ROUTE_GOALS} element={<Goals />}></Route>
            <Route path={ROUTE_FEEDBACK} element={<Feedback />}></Route>
            <Route
              path={`${ROUTE_FEEDBACK}/:eventId`}
              element={<FeedbackInfo />}
            />

            <Route
              path={ROUTE_EDIT_ORGANIZATION}
              element={<OrganizationEdit />}
            />
            <Route
              path={ROUTE_AVAILABILITY_INFO}
              element={<AvailabilityInfo />}
            />
            <Route
              path={ROUTE_AVAILABILITY_EDIT}
              element={<AvailabilityEdit />}
            />
            <Route path={ROUTE_PROFILE_EDIT} element={<ProfileEdit />} />
            <Route path={ROUTE_CALENDAR} element={<Calendar />} />
            <Route path={ROUTE_NEW_EVENT} element={<EventNew />} />
            <Route path={ROUTE_EVENT_INFO} element={<EventInfo />} />
            <Route
              path={ROUTE_EVENT_INFO_PROVIDERS}
              element={<EventInfoProviders />}
            />
            <Route path={ROUTE_EVENT_EDIT} element={<EventEdit />} />
            <Route path={ROUTE_REPORT_USER} element={<ReportUser />} />
            <Route path={ROUTE_NOTIFICATIONS} element={<Notifications />} />
            <Route
              path={ROUTE_PLAYER_SUGGESTIONS}
              element={<PlayerSuggetions />}
            />
            <Route path={ROUTE_SOCIAL} element={<Social />} />
            <Route path={ROUTE_USER_LOCATION} element={<DefaultLocation />} />

            <Route
              path={`${ROUTE_SHARE_ITEM}/:type/:id`}
              element={<ShareItem />}
            />
            <Route path={ROUTE_SHARE_ITEM} element={<ShareItemOld />} />
          </Route>
          <Route
            path={ROUTE_ABOUT_USER}
            element={
              <RequireAuth>
                <AboutUser />
              </RequireAuth>
            }
          />
          <Route path={ROUTE_RESERVANTO} element={<Reservanto />} />
          <Route path={ROUTE_VERIFICATION} element={<Verification />} />
          <Route path={ROUTE_INVITE} element={<Invite />} />
          <Route path={ROUTE_STATUS} element={<Status />} />

          <Route
            path="*"
            element={
              <Container style={{ height: '100vh' }}>
                <Text>There is nothing here!</Text>
              </Container>
            }
          />
        </RDRoutes>
      </ModalsProvider>
    </BrowserRouter>
  );
};

export default Routes;
