import React from 'react';
import styled from 'styled-components';
import { Loader } from '@mantine/core';
import { useTranslate } from '@aclito/shared/hooks';
import { Button } from '@aclito/ui-web/auth';

import { LoginFormProps } from '../../interface';

import {
  ValidatedInput,
  VStack,
  Icon,
  ValidatedPasswordInput,
} from '@/components';
import Link from '@/common/Text/Link';
import { ROUTE_REQUEST_PASSWORD } from '@/util/constants';

const LoginForm: React.FC<LoginFormProps> = ({ isLoading, onSubmit }) => {
  const t = useTranslate();
  return (
    <>
      <VStack spacing={16}>
        <ValidatedInput
          name="username"
          txLabel="auth.email"
          txPlaceholder="auth.email"
          type="email"
        />
        <ValidatedPasswordInput
          name="password"
          txLabel="auth.password"
          txPlaceholder="auth.password"
        />
        <ForgotPasswordText
          to={ROUTE_REQUEST_PASSWORD}
          textProps={{ tx: 'auth.pswsubmit.title', color: 'primary' }}
        />
      </VStack>

      <Button onClick={onSubmit}>
        {t('auth.signin.button')}
        {!isLoading ? (
          <Icon icon="forward" size={24} color="whiteText" />
        ) : (
          <Loader size={24} color="white" />
        )}
      </Button>
    </>
  );
};

const ForgotPasswordText = styled(Link)`
  color: ${({ theme }) => theme.primary};
  right: 0;
  padding: 0;
  margin-left: auto;
`;

export default LoginForm;
