import * as yup from 'yup';

import { useAppSelector, useSchema, useTranslate } from '@aclito/shared/hooks';
import { useAppDispatch } from '@aclito/shared/hooks/useDispatch';
import { ForgotPasswordForm } from '@aclito/shared/types';
import { authActions } from '@aclito/shared/redux/slices';
import { authSelectors } from '@aclito/shared/redux/slices/authSlices';

export const useSubmitNewPassword = () => {
  const isLoading = useAppSelector(authSelectors.isLoadingSubmitPassword);
  const t = useTranslate();
  const initialValues = { username: '', code: '', newPassword: '' };
  const { codeValidationSchema, userNameValidationSchema } = useSchema();
  const resetPasswordSubmitValidationSchema = yup
    .object({
      newPassword: yup
        .string()
        .min(8, t('validation.password.valid.length'))
        .required(t('validation.password.req')),
    })
    .concat(userNameValidationSchema)
    .concat(codeValidationSchema)
    .required();
  const dispatch = useAppDispatch();

  const submitNewPassword = (values: ForgotPasswordForm) =>
    dispatch(authActions.submitNewPasswordAsync(values));

  return {
    initialValues,
    resetPasswordSubmitValidationSchema,
    submitNewPassword,
    isLoading,
  };
};
