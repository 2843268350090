import { useClipboard } from '@mantine/hooks';
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  XIcon,
} from 'react-share';
import { useTheme } from 'styled-components';
import { QRCode } from 'react-qrcode-logo';
import { Divider } from '@mantine/core';
import { VStack } from '@aclito/ui-web/components/Layout';
import { useRef } from 'react';
import { Text } from '@aclito/ui-web/components/Text';
import { closeAllModals } from '@mantine/modals';
import { Organization } from '@aclito/entities';

import { InviteHStack, CopyLinkButton } from './InviteLayouts';

import { Icon, Button, ActionButton } from '@/components';
import { ROUTE_ORGANIZATION_POSTER } from '@/util/constants';
import { useNavigate } from '@/hooks/useNavigate';

type ShareProps = {
  msg: string;
  print?: boolean;
  org?: Organization;
};

const ShareModal = ({ msg, print = false, org }: ShareProps) => {
  const { copy, copied } = useClipboard();
  const qrRef = useRef<QRCode>(null);
  const navigation = useNavigate();

  const handleCopy = () => {
    copy(msg);
  };

  const download = () => {
    qrRef.current?.download('png', Date.now().toString());
  };

  const handleNavigation = () => {
    navigation(ROUTE_ORGANIZATION_POSTER, {
      state: {
        org,
      },
    });
    closeAllModals();
  };

  const theme = useTheme();

  return (
    <VStack spacing={16}>
      <InviteHStack style={{ justifyContent: 'center' }}>
        <FacebookShareButton url={msg}>
          <FacebookIcon size={64} round />
        </FacebookShareButton>
        <TwitterShareButton url={msg}>
          <XIcon size={64} round />
        </TwitterShareButton>
        <CopyLinkButton onClick={handleCopy}>
          <Icon icon="share" size="32" color="white" />
        </CopyLinkButton>
      </InviteHStack>
      {copied && <Button tx="modal.copied" w={'100%'} color={theme.copied} />}
      <Divider
        labelPosition="center"
        label={
          <>
            {
              <Text
                size={14}
                weight={500}
                tx="divider.title"
                style={{ color: theme.grey30 }}
              ></Text>
            }
          </>
        }
      />
      <VStack align={'center'} justify={'center'} spacing={16}>
        <QRCode
          ref={qrRef}
          logoHeight={80}
          logoWidth={80}
          size={250}
          logoOpacity={1}
          removeQrCodeBehindLogo={true}
          logoImage={
            new URL('../../images/logos/icon.png', import.meta.url).href
          }
          value={msg}
        />
        <ActionButton
          iconType={'download'}
          w={'100%'}
          tx="qr.download"
          onClick={download}
          color={theme.primary10}
        />
        {print && (
          <ActionButton
            iconType={'print'}
            w={'100%'}
            tx="qr.posterPrint.button"
            onClick={handleNavigation}
            color={theme.primary10}
          />
        )}
      </VStack>
    </VStack>
  );
};

export default ShareModal;
