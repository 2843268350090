import React from 'react';
import { Loader } from '@mantine/core';
import { useTranslate } from '@aclito/shared/hooks';
import { Button } from '@aclito/ui-web/auth';

import { RegisterFormProps } from '../../interface';

import {
  VStack,
  ValidatedInput,
  Icon,
  ValidatedPasswordInput,
} from '@/components';

const RegisterForm: React.FC<RegisterFormProps> = ({ isLoading, onSubmit }) => {
  const t = useTranslate();
  return (
    <>
      <VStack spacing={16}>
        <ValidatedInput
          name="username"
          txLabel="auth.email"
          txPlaceholder="auth.email"
          type="email"
        />
        <ValidatedPasswordInput
          name="password"
          txLabel="auth.password"
          txPlaceholder="auth.password"
        />
      </VStack>

      <Button onClick={onSubmit}>
        {t('auth.signup.button')}
        {!isLoading ? (
          <Icon icon="forward" size={24} color="whiteText" />
        ) : (
          <Loader size={24} color="inherit" />
        )}
      </Button>
    </>
  );
};

export default RegisterForm;
