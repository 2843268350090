import { Notification } from '@aclito/entities';
import { NotificationType } from '@aclito/enums';

import { config } from '../../../config';
import { NotificationBody } from '../../../types';

import {
  APP_NOTIFICATION_PROVIDER,
  NOTIFICATION_PLACEHOLDER,
  RESERVED_USER_NAME,
} from '@aclito/shared/util/constants';
import {
  useSelectObject,
  useTranslate,
  useTranslateDate,
} from '@aclito/shared/hooks';

export const useLocalNotifications = () => {
  const t = useTranslate();
  const tDate = useTranslateDate();
  const { getById } = useSelectObject();

  const getNotificationBody = (item: Notification): NotificationBody => {
    const userName =
      item.senderId === APP_NOTIFICATION_PROVIDER
        ? ''
        : !item.sender
        ? t('userprofile.unknown')
        : item.sender.name === RESERVED_USER_NAME
        ? t('userprofile.unknown')
        : `${item.sender.name} ${item.sender.surname}`;

    switch (item.type) {
      case NotificationType.EVENT_LEFT:
        return {
          titleTranslations: t('notification.event.left', {
            user: userName,
            name: `${item.event?.name}`,
          }),
          secondaryTitle: `${_getSport(item)} - ${_getDate(item)}`,
          message: item.body ?? undefined,
          action: t('notification.moreinfo'),
        };
      case NotificationType.EVENT_LEFT_REPEATING:
        return {
          titleTranslations: t('notification.event.left.repeating', {
            user: userName,
            name: `${item.event?.name}`,
          }),
          secondaryTitle: `${_getSport(item)} - ${_getDate(item)}`,
          message: item.body ?? undefined,
          action: t('notification.moreinfo'),
        };
      case NotificationType.EVENT_MULTIPLE_LEFT:
        return {
          titleTranslations: t('notification.event.left.multiple', {
            user: userName,
            name: `${item.event?.name}`,
          }),
          secondaryTitle: `${_getSport(item)} - ${_getDate(item)}`,
          action: t('notification.moreinfo'),
        };
      case NotificationType.EVENT_KICK:
        return {
          bodyTranslations: item.body ?? undefined,
          titleTranslations: t('notification.event.kicked', {
            name: `${item.event?.name}`,
          }),
          secondaryTitle: `${_getSport(item)} - ${_getDate(item)}`,
        };
      case NotificationType.EVENT_JOIN:
        return {
          titleTranslations: t('notification.event.joined', {
            user: userName,
            name: `${item.event?.name}`,
          }),
          secondaryTitle: `${_getSport(item)} - ${_getDate(item)}`,
          action: t('notification.moreinfo'),
        };
      case NotificationType.EVENT_JOIN_REPEATING:
        return {
          titleTranslations: t('notification.event.joined.repeating', {
            user: userName,
            name: `${item.event?.name}`,
          }),
          secondaryTitle: `${_getSport(item)} - ${_getDate(item)}`,
          action: t('notification.moreinfo'),
        };
      case NotificationType.EVENT_QUEUE_JOIN:
        return {
          titleTranslations: t('notification.event.queue.joined', {
            user: userName,
            name: `${item.event?.name}`,
          }),
          secondaryTitle: `${_getSport(item)} - ${_getDate(item)}`,
          action: t('notification.moreinfo'),
        };
      case NotificationType.EVENT_QUEUE_KICK:
        return {
          bodyTranslations: item.body ?? undefined,
          titleTranslations: t('notification.event.kicked', {
            name: `${item.event?.name}`,
          }),
          secondaryTitle: `${_getSport(item)} - ${_getDate(item)}`,
        };
      case NotificationType.EVENT_MULTIPLE_JOIN:
        return {
          titleTranslations: t('notification.event.joined.multiple', {
            user: userName,
            name: `${item.event?.name}`,
          }),
          secondaryTitle: `${_getSport(item)} - ${_getDate(item)}`,
          action: t('notification.moreinfo'),
        };
      case NotificationType.EVENT_PLAYER_CONFIRMED:
        return {
          titleTranslations: t('notification.event.player.confirmed', {
            user: userName,
            name: `${item.event?.name}`,
          }),
          secondaryTitle: `${_getSport(item)} - ${_getDate(item)}`,
          action: t('notification.moreinfo'),
        };
      case NotificationType.EVENT_MULTIPLE_PLAYER_CONFIRMED:
        return {
          titleTranslations: t('notification.event.player.confirmed', {
            user: userName,
            name: `${item.event?.name}`,
          }),
          secondaryTitle: `${_getSport(item)} - ${_getDate(item)}`,
          action: t('notification.moreinfo'),
        };
      case NotificationType.EVENT_INVITE:
        return {
          bodyTranslations: t('notification.event.invited', {
            value: userName,
          }),
          titleTranslations: t('notification.event.invite'),
          action: t('notification.moreinfo'),
        };
      case NotificationType.EVENT_STARTING:
        return {
          secondaryTitle: `${_getSport(item)} - ${_getDate(item)}`,
          titleTranslations: t('notification.event.starting'),
          action: t('notification.moreinfo'),
        };
      case NotificationType.EVENT_QUEUE_SHIFT:
        return {
          secondaryTitle: `${_getSport(item)} - ${_getDate(item)}`,
          bodyTranslations: t(
            item.event?.requireConfirmation
              ? 'notification.event.queue.shift.req.body'
              : 'notification.event.queue.shift.body',
          ),
          titleTranslations: t('notification.event.queue.shift', {
            name: `${item.event?.name}`,
          }),
          action: t('notification.moreinfo'),
        };
      case NotificationType.EVENT_QUEUE_MULTIPLE_JOIN:
        return {
          titleTranslations: t('notification.event.queue.multiple.joined', {
            user: userName,
            name: `${item.event?.name}`,
          }),
          secondaryTitle: `${_getSport(item)} - ${_getDate(item)}`,
          action: t('notification.moreinfo'),
        };
      case NotificationType.EVENT_CHANGE:
        return {
          secondaryTitle: `${_getSport(item)} - ${_getDate(item)}`,
          titleTranslations: t('notification.event.changed'),
          action: t('notification.moreinfo'),
        };
      case NotificationType.EVENT_CHANGE_REPEATING:
        return {
          secondaryTitle: `${_getSport(item)} - ${_getDate(item)}`,
          titleTranslations: t('notification.event.changed.repeating'),
          action: t('notification.moreinfo'),
        };
      case NotificationType.EVENT_DELETE:
        return {
          secondaryTitle: item.body ?? undefined,
          titleTranslations: t('notification.event.deleted'),
        };
      case NotificationType.EVENT_MULTIPLE_QUEUE_LEFT:
        return {
          secondaryTitle: item.body ?? undefined,
          titleTranslations: t('notification.event.left.multiple', {
            user: userName,
            name: `${item.event?.name}`,
          }),
        };
      case NotificationType.EVENT_QUEUE_LEFT:
        return {
          secondaryTitle: item.body ?? undefined,
          titleTranslations: t('notification.event.left.multiple', {
            user: userName,
            name: `${item.event?.name}`,
          }),
        };
      case NotificationType.EVENT_DELETE_REPEATING:
        return {
          secondaryTitle: item.body ?? undefined,
          titleTranslations: t('notification.event.deleted.repeating'),
        };
      case NotificationType.EVENT_CONFIRM:
        return {
          secondaryTitle: `${_getSport(item)} - ${_getDate(item)}`,
          titleTranslations: t('notification.event.confirm'),
          action: t('notification.moreinfo'),
        };
      case NotificationType.ORG_ROLE_CHANGE:
        return {
          titleTranslations: t('notification.org.rolechanged', {
            org: item.org?.name,
          }),
          secondaryTitle: t('notification.org.role', {
            role: item.body?.trim() && getById(item.body, config.orgRoles).item,
          }),
          action: t('notification.moreinfo'),
        };
      case NotificationType.FEED_CREATE:
        return {
          titleTranslations: t('notification.feed.create', {
            name: item.org?.name,
          }),
          action: t('notification.moreinfo'),
        };
      case NotificationType.FEED_UPDATE:
        return {
          titleTranslations: t('notification.feed.update', {
            name: item.org?.name,
          }),
          action: t('notification.moreinfo'),
        };
      case NotificationType.ORG_KICK:
        return {
          titleTranslations: t('notification.org.kick', {
            org: item.org?.name,
          }),
          action: t('notification.moreinfo'),
        };
      case NotificationType.ORG_LEFT:
        return {
          titleTranslations: t('notification.org.left', {
            user: userName,
            name: `${item.org?.name}`,
          }),
          bodyTranslations: item.body ?? undefined,
          action: t('notification.moreinfo'),
        };
      case NotificationType.ORG_DELETE:
        return {
          bodyTranslations: item.body ?? undefined,
          titleTranslations: t('notification.org.deleted'),
        };
      case NotificationType.ORG_JOIN:
        return {
          titleTranslations: t('notification.org.join', {
            user: userName,
            name: `${item.org?.name}`,
          }),
          action: t('notification.moreinfo'),
        };

      default:
        break;
    }

    return {
      ...NOTIFICATION_PLACEHOLDER,
    };
  };
  const _getDate = (item: Notification) =>
    `${tDate(item.event?.date ?? '', 'date.notification')}`;

  const _getSport = (item: Notification) =>
    item.event && getById(item.event.activityType, config.activities).item;

  return {
    getNotificationBody,
  };
};
