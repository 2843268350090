import { useEffect } from 'react';
import { isAndroid, isMobile } from 'react-device-detect';
import { config } from '@aclito/shared/config';

const aclitoWebURL = `${window.location.protocol}//${window.location.host}`;

const Invite = () => {
  const aclitoURL = isAndroid
    ? config.URL.googleStoreURL
    : config.URL.appleStoreURL;

  useEffect(() => {
    if (isMobile) {
      document.location = aclitoURL;
    } else {
      document.location = aclitoWebURL;
    }
  }, [aclitoURL]);

  return <div></div>;
};

export default Invite;
