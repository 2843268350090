import { Icon } from '@aclito/ui-web/components/Icons/Icon';
import { HStack, VStack } from '@aclito/ui-web/components/Layout';
import { config } from '@aclito/shared/config';
import { useTheme } from 'styled-components';
import { Link } from 'react-router-dom';

const Contacts = () => {
  const theme = useTheme();

  return (
    <VStack align="center" spacing={8}>
      <Link
        to="mailto:info@aclito.com"
        style={{ color: theme.primary, textDecoration: 'none' }}
      >
        info@aclito.com
      </Link>
      <HStack spacing={16}>
        <Link to={config.URL.instagramURL} target="_blank">
          <Icon icon="instagram" color="primary" />
        </Link>
        <Link to={config.URL.facebookURL} target="_blank">
          <Icon icon="facebook" color="primary" />
        </Link>
        <Link to={config.URL.linkeinURL} target="_blank">
          <Icon icon="linkedin" color="primary" />
        </Link>
        <Link to={config.URL.linkeinURL} target="_blank">
          <Icon icon="youtube" color="primary" />
        </Link>
      </HStack>
    </VStack>
  );
};

export default Contacts;
