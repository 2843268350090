import React from 'react';
import { useSubmitNewPassword } from '@aclito/shared/features/auth/hooks/useSubmitNewPassword';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ForgotPasswordForm } from '@aclito/shared/types';
import * as yup from 'yup';
import { Screen } from '@aclito/ui-web/auth/Screen';
import { Title, Header, Box } from '@aclito/ui-web/auth';

import Footer from '../../components/Footer/Footer';

import { PasswordForm } from './components';

import { Container } from '@/components';
import { ROUTE_SIGN_IN } from '@/util/constants';
import { useNavigate } from '@/hooks/useNavigate';

const SubmitNewPassword: React.FC = () => {
  const navigate = useNavigate();

  const {
    initialValues,
    resetPasswordSubmitValidationSchema,
    submitNewPassword,
    isLoading,
  } = useSubmitNewPassword();

  const handleSubmit = async (values: ForgotPasswordForm) => {
    const res = await submitNewPassword(values);
    if (!res.payload) {
      navigate(ROUTE_SIGN_IN);
    }
  };

  type ResetPasswordSchemaType = yup.InferType<
    typeof resetPasswordSubmitValidationSchema
  >;

  const methods = useForm<ResetPasswordSchemaType>({
    defaultValues: initialValues,
    resolver: yupResolver(resetPasswordSubmitValidationSchema),
  });

  return (
    <Box>
      <Header />
      <Container>
        <Screen>
          <Title tx="auth.signin.reset" />
          <FormProvider {...methods}>
            <PasswordForm
              isLoading={isLoading}
              onSubmit={methods.handleSubmit(handleSubmit)}
            />
          </FormProvider>
        </Screen>
      </Container>
      <Footer />
    </Box>
  );
};

export default SubmitNewPassword;
