import { FC, useEffect, useState } from 'react';
import { useAppSelector } from '@aclito/shared/hooks/useSelector';
import { Logger } from '@aclito/shared/util/logger';
import { profileSelectors } from '@aclito/shared/redux/slices/profileSlices';
import { authSelectors } from '@aclito/shared/redux/slices/authSlices';
import { loadAllAsync } from '@aclito/shared/redux/thunks/thunks';
import { LoadingOverlay } from '@mantine/core';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { useAppDispatch } from '@aclito/shared/hooks/useDispatch';
import { locationActions } from '@aclito/shared/redux/slices';

import { useShowTranslatedNotification } from '@/hooks';

const withData = <T extends object>(Component: FC<T>): FC<T> => {
  const Data: FC<T> = (props) => {
    Logger.debug('rerender-withData');
    const dispatch = useAppDispatch();
    const userInfo = useAppSelector(profileSelectors.userInfo);
    const firstTimeLogin = useAppSelector(authSelectors.firstTimeLogin);
    const [completed, setCompleted] = useState(false);
    const { signOut } = useAuthenticator((context) => [context.route]);
    const showMessage = useShowTranslatedNotification();
    const isLoading = firstTimeLogin ? false : !completed;

    const checkData = async () => {
      if (!userInfo) {
        try {
          if (!firstTimeLogin) {
            try {
              const res = await dispatch(loadAllAsync()).unwrap();
              setCompleted(res);
            } catch (error) {
              signOut();
            }
          }
        } catch (error) {
          showMessage('notSignInError');
        }
      }
    };

    useEffect(() => {
      checkData();
    }, [userInfo]);

    useEffect(() => {
      if (userInfo?.location) {
        dispatch(
          locationActions.updateUserLocation({
            lon: userInfo.location.lon,
            latitude: userInfo.location.lat,
            lng: userInfo.location.lon,
            longitude: userInfo.location.lon,
            lat: userInfo.location.lat,
          }),
        );
      }
    }, [userInfo?.location]);

    return isLoading ? (
      <LoadingOverlay visible={isLoading} />
    ) : (
      <Component {...props} />
    );
  };
  return Data;
};

export default withData;
