import ReactCrop from 'react-image-crop';
import styled, { useTheme } from 'styled-components';
import { useTranslate } from '@aclito/shared/hooks';

import { Modal } from '../Modal';
import { HStack, VStack } from '../Layout';
import { Slider } from '../Form';
import { Button } from '../Button';

import ImagePreview from './ImagePreview';
import CurrentPicture from './CurrentPicture';
import DropzoneContent from './DropzoneContent';
import { ImageDropzoneProps } from './types';
import { useDropZone } from './useDropZone';

import 'react-image-crop/dist/ReactCrop.css';

const ImageDropzone = ({
  onChange,
  currentImage,
  onRemove,
  imageOnly,
  circular = true,
}: ImageDropzoneProps) => {
  const {
    onSelectFile,
    imgSrc,
    imgRef,
    aspect,
    onImageLoad,
    onCompleteCrop,
    completedCrop,
    previewCanvasRef,
    onChangeCrop,
    crop,
    scale,
    rotate,
    setRotate,
    setScale,
    preview,
    submit,
    open,
    toggle,
    generateSeed,
    rotateMarks,
    scaleMarks,
    handleUploadClick,
    ref,
    setPreview,
  } = useDropZone(onChange);

  const t = useTranslate();
  const theme = useTheme();
  return (
    <VStack spacing={8} style={{ flex: 1 }}>
      <StyledInput
        name="file"
        type="file"
        accept="image/*"
        ref={ref}
        onChange={(e) => {
          onSelectFile(e);
          toggle();
        }}
      />
      {currentImage && !preview ? (
        <ImageContainer>
          <CurrentPicture currentImage={currentImage} circular={circular} />
        </ImageContainer>
      ) : (
        <ImageContainer>
          <ImageView>
            {preview ? (
              <ImagePreview circular={circular} preview={preview} />
            ) : (
              <DropzoneContent />
            )}
          </ImageView>
        </ImageContainer>
      )}

      <HStack
        spacing={4}
        style={{
          width: '100%',
        }}
      >
        <StyledButton onClick={handleUploadClick}>
          {t('image.upload')}
        </StyledButton>
        {!imageOnly && (
          <StyledButton onClick={generateSeed}>
            {t('avatar.generate')}
          </StyledButton>
        )}
        {currentImage && (
          <StyledButton
            onClick={() => {
              setPreview(undefined);
              onRemove?.();
              // @ts-ignore
              ref.current.value = '';
            }}
            style={{ background: theme.red10, color: theme.red }}
          >
            {t('remove')}
          </StyledButton>
        )}
      </HStack>

      <Modal
        styles={{
          body: {
            height: '100%',
          },
        }}
        opened={open}
        centered
        onClose={toggle}
      >
        <HStack style={{ justifyContent: 'center' }}>
          {imgSrc && (
            <ReactCrop
              style={{ maxHeight: 300, maxWidth: 300 }}
              crop={crop}
              circularCrop={circular}
              onChange={onChangeCrop}
              onComplete={onCompleteCrop}
              aspect={aspect}
            >
              <img
                ref={imgRef}
                src={imgSrc}
                style={{ transform: `scale(${scale}) rotate(${rotate}deg)` }}
                onLoad={onImageLoad}
              />
            </ReactCrop>
          )}

          {!!completedCrop && (
            <PreviewCanvas circular={circular} ref={previewCanvasRef} />
          )}

          {imgSrc && (
            <VStack spacing={40}>
              <Slider
                txLabel={'scale'}
                onChange={setScale}
                style={{ width: 300 }}
                min={1}
                step={1}
                max={5}
                marks={scaleMarks}
              />
              <Slider
                txLabel={'rotate'}
                onChange={setRotate}
                style={{ width: 300 }}
                min={-180}
                step={5}
                max={180}
                marks={rotateMarks}
              />
              <Button onClick={submit} tx="submit"></Button>
            </VStack>
          )}
        </HStack>
      </Modal>
    </VStack>
  );
};

const StyledInput = styled.input`
  display: none;
`;

const PreviewCanvas = styled.canvas<{ circular?: boolean }>`
  height: 300px;
  width: 300px;
  border-radius: ${({ circular }) => (circular ? '50%' : 0)};
  object-fit: contain;
  border: 1px solid ${({ theme }) => theme.grey20};
`;

const StyledButton = styled.button`
  flex: 8rem 1 0;
  display: flex;
  width: 100%;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.primary10};
  padding: 10px 0;
  border: 0;
  color: ${({ theme }) => theme.primary};
  &:hover {
    filter: brightness(95%);
    cursor: pointer;
  }
`;

const ImageContainer = styled.div`
  display: flex;
  width: 100%;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.white};
  padding: 10px 0;
`;

const ImageView = styled.div`
  width: 200px;
  height: 200px;
  border-radius: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.white};
  :hover {
    cursor: pointer;
  }
`;

export default ImageDropzone;
