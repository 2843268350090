import React, { useState } from 'react';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslate } from '@aclito/shared/hooks/useTranslate';
import { config } from '@aclito/shared/config';
import styled from 'styled-components';
import { useAppSelector } from '@aclito/shared/hooks/useSelector';
import { profileSelectors } from '@aclito/shared/redux/slices/profileSlices';
import { useNavigate } from 'react-router-dom';
import { useDisclosure } from '@mantine/hooks';
import { useSchema } from '@aclito/shared/hooks/useSchema';
import { Loader } from '@mantine/core';
import { sizeMax } from '@aclito/ui-web/utils/mediaQueries';

import { useAboutUser } from './hooks/useAboutUser';

import {
  SectionGrid,
  Checkbox,
  HStack,
  VStack,
  Text,
  ActionButton,
  BackButton,
  TitleBar,
  Title,
} from '@/components';
import DefaultLocation from '@/common/DefaultLocation/DefaultLocation';
import ProfileEditForm from '@/common/ProfileEditForm/ProfileEditForm';
import { ROUTE_EVENTS } from '@/util/constants';

const AboutUser: React.FC = () => {
  const { signOut, user } = useAuthenticator((context) => [
    context.route,
    context.signOut,
  ]);
  const isLoading = useAppSelector(profileSelectors.isLoadingUpdateMyProfile);
  const isLoadingPopulate = useAppSelector(profileSelectors.isLoadingPopulate);
  const t = useTranslate();
  const nav = useNavigate();
  if (user?.attributes?.['custom:Login'] !== 'FIRST_TIME') {
    nav(ROUTE_EVENTS);
  }

  const [tac, setTac] = useState(false);
  const [isMap, { toggle }] = useDisclosure(false);

  const {
    initialValues,
    handleUpdateUser,
    placeholderName,
    placeholderSurname,
  } = useAboutUser();

  const { userInfoValidationSchema } = useSchema();

  const methods = useForm({
    defaultValues: initialValues,
    //@ts-ignore
    resolver: yupResolver(userInfoValidationSchema),
    mode: 'onChange',
  });

  const handleCheckboxChange = () => {
    setTac(!tac);
  };

  return !isLoadingPopulate ? (
    <SectionGrid
      $isOpen={false}
      style={{ alignSelf: 'center', maxWidth: sizeMax.tablet, padding: 16 }}
    >
      <TitleDiv>
        {!isMap ? (
          <TitleBar>
            <Title tx="aboutuser.title" />
            <ActionButton
              onClick={signOut}
              bg={'red10'}
              textcolor={'red'}
              iconType={'close'}
            />
          </TitleBar>
        ) : (
          <BackButton onClick={toggle} />
        )}

        <FormProvider {...methods}>
          {isMap && (
            <>
              <DefaultLocation />
            </>
          )}
          <ConditionalDisplay $visible={!isMap}>
            <ProfileEditForm
              {...{
                isLoading,
                initialValues,
                submit: handleUpdateUser,
                tac,
                placeholderName,
                placeholderSurname,
                toggle,
              }}
            />
            <VStack style={{ paddingTop: 16 }} align={'center'}>
              <HStack style={{ flexWrap: 'nowrap' }}>
                <Checkbox
                  onChange={handleCheckboxChange}
                  checked={tac}
                  size={'md'}
                  required
                />
                <Text tx="auth.termsAndConditions" />
              </HStack>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={`${config.URL.aclitoTacURL}`}
              >
                {t('auth.policyLink')}
              </a>
            </VStack>
          </ConditionalDisplay>
        </FormProvider>
      </TitleDiv>
    </SectionGrid>
  ) : (
    <VStack h={'100vh'} justify="center" align="center">
      <Loader />
      <Text weight={500} tx="loading.warning" />
    </VStack>
  );
};

const ConditionalDisplay = styled.div<{ $visible: boolean }>`
  display: ${(props) => (props.$visible ? 'block' : 'none')};
`;

const TitleDiv = styled.div`
  padding-top: 20px;
  background-color: ${({ theme }) => theme.background};
  color: ${({ theme }) => theme.grey80};
`;
export default AboutUser;
