export const ROUTE_ADMIN = '/admin';
export const ROUTE_ADMIN_EDIT_ORG = '/admin-edit-org';
export const ROUTE_SIGN_IN = '/sign-in';
export const ROUTE_SIGN_UP = '/sign-up';
export const ROUTE_EVENTS = '/events';
export const ROUTE_SEARCH = '/search';
export const ROUTE_PROFILE = '/profile';
export const ROUTE_PROFILE_ALIASES = '/profile-aliases';
export const ROUTE_PROFILE_ALIASES_EDIT = '/profile-aliases-edit';
export const ROUTE_PROFILE_ALIASES_CREATE = '/profile-aliases-create';
export const ROUTE_CALENDAR = '/calendar';
export const ROUTE_EMBEDDED_CALENDAR = '/calendar-embedded';
export const ROUTE_ORGANIZATIONS = '/organizations';
export const ROUTE_SETTINGS = '/settings';
export const ROUTE_AVAILABILITY = '/availability';
export const ROUTE_ALIAS_INFO = '/alias-info';
export const ROUTE_NEW_AVAILABILITY = '/new-availability';
export const ROUTE_AVAILABILITY_INFO = '/availability-info';
export const ROUTE_AVAILABILITY_EDIT = '/availability-edit';
export const ROUTE_REQUEST_PASSWORD = '/request-password';
export const ROUTE_SUBMIT_PASSWORD = '/submit-password';
export const ROUTE_ABOUT_USER = '/about-user';
export const ROUTE_NEW_EVENT = '/new-event';
export const ROUTE_PROFILE_EDIT = '/profile-edit';
export const ROUTE_EVENT_INFO = '/event-info';
export const ROUTE_NOTES = '/notes';
export const ROUTE_NOTES_USER = '/notes-user';
export const ROUTE_MAP = '/map';
export const ROUTE_GOALS = '/goals';
export const ROUTE_FEEDBACK = '/feedback';
export const ROUTE_ORGANIZATION_POSTER = '/organization-poster';

export const ROUTE_VERIFICATION = '/verification';
export const ROUTE_INVITE = '/invite';
export const ROUTE_STATUS = '/status';

export const ROUTE_EVENT_INFO_PROVIDERS = '/event-info-providers';
export const ROUTE_NEW_ORGANIZATION = '/new-organization';
export const ROUTE_FEED_EDIT = '/edit-feed';
export const ROUTE_FEED_INFO = '/info-feed';
export const ROUTE_FEEDS = '/feeds';
export const ROUTE_EDIT_ORGANIZATION = '/edit-organization';
export const ROUTE_USER_PROFILE = '/user-profile';
export const ROUTE_USER_LOCATION = '/user-location';
export const ROUTE_EVENT_EDIT = '/event-edit';
export const ROUTE_REPORT_USER = '/report-user';
export const ROUTE_NOTIFICATIONS = '/notifications';
export const ROUTE_PLAYER_SUGGESTIONS = '/player-suggestions';
export const ROUTE_SOCIAL = '/social';
export const ROUTE_SHARE_ITEM = '/share-item';
export const ROUTE_RESERVANTO = '/reservanto';
export const MAP_API_KEY = 'AIzaSyBW2osQQT2A356oCvy_hI6D7firVcNaj5c'; // AIzaSyBW2osQQT2A356oCvy_hI6D7firVcNaj5c
export const FB_APP_ID = '691780612239180';
export const SHARE_URL = `${window.location.protocol}//${window.location.host}${ROUTE_SHARE_ITEM}`;

export const VAPID_KEY =
  'BJ9gXOnIUTLctbeIBzDCvEuVSwld9tOwMigMCcr-I1N0UF197LdnjuzVzYkfxFrLMIBPaHElempwWT-v5bTK4lg';

export const MAP_BORDER_RADIUS = 20;
