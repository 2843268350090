import { ApiAuthErrors } from '../api/types/types';
import { TranslateKey, TranslateValues } from '../types';
import { config } from '../config';

export interface FlashMessageInterface {
  type: 'none' | 'default' | 'info' | 'success' | 'danger' | 'warning';
  autoHide?: boolean;
  translation: TranslateKey;
  values?: TranslateValues;
}
export type EventFlashMessageKeys =
  | 'nowOrNeverListEmpty'
  | 'nowOrNeverListError'
  | 'searchEventsListError'
  | 'searchEventsListEmpty'
  | 'searchEventsLocationError'
  | 'searchEventsLocationListEmpty'
  | 'eventUpdatedAsFinishedSuccess'
  | 'eventUpdatedAsFinishedError'
  | 'findEventError'
  | 'joinEventError'
  | 'joinEventSuccess'
  | 'createEventSuccess'
  | 'createEventPermissionError'
  | 'createEventError'
  | 'updateEventSuccess'
  | 'updateEventError'
  | 'updateEventPermissionError'
  | 'deleteEventSuccess'
  | 'deleteEventError'
  | 'joinEventPermissionError'
  | 'joinEventInvalidGender'
  | 'joinEventUnknownGender'
  | 'leaveEventSuccess'
  | 'leaveEventError'
  | 'invitePlayerToEventSuccess';

export type ProfileFlashMessageKeys =
  | 'updateMyProfileSuccess'
  | 'updateMyProfileError'
  | 'createContactRequestSuccess'
  | 'createContactRequestError'
  | 'createContactPermission'
  | 'contactAddSuccess'
  | 'contactAddError'
  | 'contactRemoveSuccess'
  | 'contactRemoveError'
  | 'accountDeleteSuccess'
  | 'accountDeleteError'
  | 'locationUpdateSuccess'
  | 'locationUpdateError';

export type AvailabilityFlashMessageKeys =
  | 'availabilityCreateSuccess'
  | 'availabilityCreateError'
  | 'availabilityUpdateSuccess'
  | 'availabilityUpdaterror'
  | 'availabilitiesListError'
  | 'availabilitiesListErrorEmpty'
  | 'availabilityDeleteSuccess'
  | 'availabilityDeleteError';

export type OrgFlashMessageKeys =
  | 'orgCreateSuccess'
  | 'orgCreateError'
  | 'orgCreateErrorQuota'
  | 'orgUpdateSuccess'
  | 'orgUpdateError'
  | 'orgJoinSuccess'
  | 'orgJoinError'
  | 'orgListError'
  | 'orgAddMemberSuccess'
  | 'orgAddMemberError'
  | 'orgListErrorEmpty'
  | 'orgFindError'
  | 'orgLeavePermission'
  | 'orgLeaveSuccess'
  | 'orgJoinPermission';

export type FeedFlashMessagesKeys =
  | 'feedCreateSuccess'
  | 'feedCreateError'
  | 'feedUpdateSuccess'
  | 'feedUpdateError'
  | 'feedListError'
  | 'feedDeleteSuccess'
  | 'feedDeleteError';

export type PlacesFlashMessagesKeys =
  | 'placeCreateSuccess'
  | 'placeCreateError'
  | 'placeUpdateSuccess'
  | 'placeUpdateError';

export type AppFlashMessageKeys =
  | 'eventInfoEditPlace'
  | 'availabilityEditHasEvent'
  | 'availabilityGender'
  | 'availabilityEditHasAvailability'
  | 'locationServicesSettings'
  | 'locationServicesConfirmed'
  | 'notAdmin'
  | 'qrSaved'
  | 'qrSavedPermission'
  | 'calendarPermission'
  | 'calendarSave'
  | 'calendarError'
  | 'calendarRemove'
  | 'calendarRemoveError'
  | 'calendarUpdate'
  | 'calendarUpdateError'
  | 'uploadImage'
  | 'qrSavedError';

export type NoteFlashMessageKeys = 'noteUserPermission';

export type FlashMessagesKeys =
  | 'pushNotificationError'
  | 'signUpSuccess'
  | 'notSignInError'
  | 'requestNewPasswordSuccess'
  | 'submitNewPasswordSuccess'
  | 'reportCreateSuccess'
  | 'reportCreateError'
  | 'reportCreatePermissionError'
  | 'chatLeaveSuccess'
  | 'chatLeaveError'
  | 'chatsLoadError'
  | 'notificationDeleteAllError'
  | 'notificationDeleteAllSuccess'
  | 'playerSuggestionsSuccess'
  | 'playerSuggestionsError'
  | 'playerSuggestionsEmpty'
  | 'connectionError'
  | 'connectionSuccess'
  | OrgFlashMessageKeys
  | AvailabilityFlashMessageKeys
  | EventFlashMessageKeys
  | ProfileFlashMessageKeys
  | ApiAuthErrors
  | PlacesFlashMessagesKeys
  | FeedFlashMessagesKeys
  | AppFlashMessageKeys
  | NoteFlashMessageKeys;

export const flashMessages: Record<FlashMessagesKeys, FlashMessageInterface> = {
  qrSaved: {
    type: 'info',
    translation: 'qr.saved',
  },
  uploadImage: {
    type: 'info',
    translation: 'upload.image.error',
  },
  qrSavedPermission: {
    type: 'info',
    translation: 'qr.qrSavedPermission',
  },
  calendarPermission: {
    type: 'info',
    translation: 'calendar.permission',
  },
  calendarSave: {
    type: 'success',
    translation: 'calendar.successSave',
  },
  calendarRemove: {
    type: 'success',
    translation: 'calendar.remove.success',
  },
  calendarRemoveError: {
    type: 'danger',
    translation: 'calendar.remove.error',
  },
  calendarUpdate: {
    type: 'info',
    translation: 'calendar.update.success',
  },
  calendarUpdateError: {
    type: 'danger',
    translation: 'calendar.update.error',
  },
  calendarError: {
    type: 'danger',
    translation: 'calendar.saveError',
  },
  qrSavedError: {
    type: 'danger',
    translation: 'qr.savedError',
  },
  notAdmin: {
    type: 'danger',
    autoHide: true,
    translation: 'auth.not.admin',
  },
  locationServicesSettings: {
    type: 'info',
    autoHide: false,
    translation: 'location.services.openSettings',
  },
  locationServicesConfirmed: {
    type: 'success',
    translation: 'location.services.liveConfirm',
  },
  availabilityGender: {
    type: 'warning',
    translation: 'availability.enter.text',
  },
  feedCreateError: {
    type: 'danger',
    translation: 'error.createFeed',
  },
  feedUpdateError: {
    type: 'danger',
    translation: 'error.updateFeed',
  },
  feedListError: {
    type: 'danger',
    translation: 'error.listFeeds',
  },
  feedCreateSuccess: {
    type: 'success',
    translation: 'success.createFeed',
  },
  feedUpdateSuccess: {
    type: 'success',
    translation: 'success.updateFeed',
  },
  feedDeleteError: {
    type: 'danger',
    translation: 'error.deleteFeed',
  },
  feedDeleteSuccess: {
    type: 'success',
    translation: 'success.deleteFeed',
  },
  noteUserPermission: {
    type: 'danger',
    translation: 'error.noteUserPermission',
  },
  notSignInError: {
    type: 'warning',
    translation: 'error.notSignInError',
  },
  availabilityEditHasEvent: {
    type: 'warning',
    translation: 'availability.new.error.event',
  },
  availabilityEditHasAvailability: {
    type: 'warning',
    translation: 'availability.new.error.availability',
  },
  eventInfoEditPlace: {
    type: 'warning',
    translation: 'events.new.place.error.body',
  },
  playerSuggestionsSuccess: {
    type: 'success',
    translation: 'success.playerSuggestions',
  },
  playerSuggestionsEmpty: {
    type: 'info',
    translation: 'error.empty.playerSuggestions',
  },
  playerSuggestionsError: {
    type: 'danger',
    translation: 'error.playerSuggestions',
  },
  locationUpdateError: { type: 'danger', translation: 'error.location.update' },
  locationUpdateSuccess: {
    type: 'success',
    translation: 'success.location.update',
  },
  notificationDeleteAllError: {
    type: 'danger',
    translation: 'error.deleteAllNotifications',
  },
  notificationDeleteAllSuccess: {
    type: 'success',
    translation: 'success.deleteAllNotifications',
  },
  placeCreateSuccess: {
    type: 'success',
    translation: 'success.createPlace',
  },
  placeCreateError: {
    type: 'danger',
    translation: 'error.createPlace',
  },
  placeUpdateSuccess: {
    type: 'success',
    translation: 'success.updatePlace',
  },
  placeUpdateError: {
    type: 'danger',
    translation: 'error.updatePlace',
  },
  chatLeaveSuccess: {
    type: 'success',
    translation: 'success.leaveChat',
  },
  chatLeaveError: {
    type: 'danger',
    translation: 'error.leaveChat',
  },
  chatsLoadError: { type: 'danger', translation: 'error.loadChats' },
  invitePlayerToEventSuccess: {
    type: 'success',
    translation: 'success.invitePlayerToEvent',
  },
  leaveEventSuccess: { type: 'success', translation: 'success.leaveEvent' },
  leaveEventError: { type: 'danger', translation: 'error.leaveEvent' },
  accountDeleteError: { type: 'danger', translation: 'error.deleteAccount' },
  accountDeleteSuccess: {
    type: 'success',
    translation: 'success.deleteAccount',
  },
  contactAddError: { type: 'danger', translation: 'error.contactAdd' },
  contactAddSuccess: { type: 'success', translation: 'success.contactAdd' },
  contactRemoveError: { type: 'danger', translation: 'error.removeContact' },
  contactRemoveSuccess: {
    type: 'success',
    translation: 'success.removeContact',
  },
  createContactRequestSuccess: {
    type: 'success',
    translation: 'success.contactRequest',
  },
  createContactRequestError: {
    type: 'danger',
    translation: 'error.contactRequest',
  },
  createContactPermission: {
    type: 'info',
    translation: 'error.permission.contactRequest',
  },
  updateMyProfileSuccess: {
    type: 'success',
    translation: 'success.updateProfile',
  },
  updateMyProfileError: {
    type: 'danger',
    translation: 'error.updateProfile',
  },
  joinEventPermissionError: {
    type: 'danger',
    translation: 'error.joinEventPermission',
  },
  joinEventInvalidGender: {
    type: 'danger',
    translation: 'events.invalid.gender',
  },
  joinEventUnknownGender: {
    type: 'danger',
    translation: 'events.unknown.gender',
  },
  deleteEventSuccess: {
    type: 'success',
    translation: 'success.deleteEvent',
  },
  deleteEventError: {
    type: 'danger',
    translation: 'error.deleteEvent',
  },
  updateEventSuccess: {
    type: 'success',
    translation: 'success.updateEvent',
  },
  updateEventError: {
    type: 'danger',
    translation: 'error.eventUpdate',
  },
  updateEventPermissionError: {
    type: 'danger',
    translation: 'error.permission.eventUpdate',
  },
  createEventError: {
    type: 'danger',
    translation: 'error.eventCreate',
  },
  createEventPermissionError: {
    type: 'danger',
    translation: 'error.permission.eventCreate',
  },
  createEventSuccess: {
    type: 'success',
    translation: 'success.createEvent',
  },
  pushNotificationError: {
    type: 'info',
    autoHide: false,
    translation: 'error.pushNotification',
  },
  joinEventError: {
    type: 'danger',
    translation: 'error.joinEvent',
  },
  joinEventSuccess: {
    type: 'success',
    translation: 'success.joinEvent',
  },
  findEventError: {
    type: 'danger',
    translation: 'error.findEvent',
  },
  eventUpdatedAsFinishedError: {
    type: 'danger',
    translation: 'error.eventUpdatedAsFinished',
  },
  eventUpdatedAsFinishedSuccess: {
    type: 'success',
    translation: 'success.eventUpdatedAsFinished',
  },
  searchEventsListEmpty: {
    type: 'info',
    translation: 'error.empty.searchEventList',
  },
  searchEventsListError: {
    type: 'danger',
    translation: 'error.searchEventList',
  },
  searchEventsLocationError: {
    type: 'danger',
    translation: 'error.searchEventListLocation',
  },
  searchEventsLocationListEmpty: {
    type: 'info',
    translation: 'error.empty.searchEventLocationList',
  },
  nowOrNeverListEmpty: {
    type: 'info',
    translation: 'error.empty.listNowOrNever',
  },
  nowOrNeverListError: {
    type: 'danger',
    translation: 'error.listNowOrNever',
  },
  availabilityDeleteSuccess: {
    type: 'success',
    translation: 'success.availability.delete',
  },
  availabilityDeleteError: {
    type: 'danger',
    translation: 'error.availability.delete',
  },
  availabilityCreateSuccess: {
    type: 'success',
    translation: 'success.availability.create',
  },
  availabilityCreateError: {
    type: 'danger',
    translation: 'error.availability.create',
  },
  availabilityUpdateSuccess: {
    type: 'success',
    translation: 'success.availability.update',
  },
  availabilityUpdaterror: {
    type: 'danger',
    translation: 'error.availability.update',
  },
  availabilitiesListError: {
    type: 'danger',
    translation: 'error.availabilities.list',
  },
  availabilitiesListErrorEmpty: {
    type: 'info',
    translation: 'error.empty.availabilities.list',
  },
  reportCreatePermissionError: {
    type: 'warning',
    translation: 'error.permission.createReport',
  },
  reportCreateError: {
    type: 'danger',
    translation: 'error.createReport',
  },
  reportCreateSuccess: {
    type: 'success',
    translation: 'success.createreport',
  },
  orgAddMemberError: { type: 'danger', translation: 'error.orgAddMember' },
  orgAddMemberSuccess: { type: 'success', translation: 'success.orgAddMember' },
  orgCreateSuccess: {
    type: 'success',
    translation: 'success.CreateOrg',
  },
  orgCreateErrorQuota: {
    type: 'danger',
    translation: 'error.quota.CreateOrg',
    values: { value: config.quotas.users.org },
  },
  orgCreateError: {
    type: 'danger',
    translation: 'error.CreateOrg',
  },
  orgUpdateError: {
    type: 'danger',
    translation: 'error.UpdateOrg',
  },
  orgUpdateSuccess: {
    type: 'success',
    translation: 'success.UpdateOrg',
  },
  orgJoinError: {
    type: 'danger',
    translation: 'error.JoinOrg',
  },
  orgJoinPermission: {
    type: 'danger',
    translation: 'error.JoinOrgPermission',
  },
  orgLeavePermission: {
    type: 'info',
    translation: 'error.LeaveOrgPermission',
  },
  orgLeaveSuccess: {
    type: 'success',
    translation: 'success.leaveOrg',
  },
  orgJoinSuccess: {
    type: 'success',
    translation: 'success.JoinOrg',
  },
  orgListError: {
    type: 'danger',
    translation: 'error.ListOrg',
  },
  orgListErrorEmpty: {
    type: 'info',
    translation: 'error.empty.ListOrg',
  },
  orgFindError: {
    type: 'danger',
    translation: 'error.FindOrg',
  },
  signUpSuccess: {
    type: 'info',
    translation: 'success.register',
  },
  requestNewPasswordSuccess: {
    type: 'success',
    translation: 'success.requestNewPassword',
  },
  submitNewPasswordSuccess: {
    type: 'success',
    translation: 'success.submitNewPassword',
  },
  NotAuthorizedException: {
    type: 'danger',
    translation: 'error.NotAuthorizedException',
  },
  UserNotConfirmedException: {
    type: 'danger',
    translation: 'error.UserNotConfirmedException',
  },
  AttributesErrorException: {
    type: 'danger',
    translation: 'error.AttributesErrorException',
  },
  ExpiredCodeException: {
    type: 'danger',
    translation: 'error.ExpiredCodeException',
  },
  LimitExceededException: {
    type: 'danger',
    translation: 'error.LimitExceededException',
  },
  InvalidParameterException: {
    type: 'danger',
    translation: 'error.InvalidParameterException',
  },
  InvalidPasswordException: {
    type: 'danger',
    translation: 'error.InvalidPasswordException',
  },
  UsernameExistsException: {
    type: 'danger',
    translation: 'error.UsernameExistsException',
  },
  NoUserException: {
    type: 'danger',
    translation: 'error.NoUserException',
  },
  PasswordErrorException: {
    type: 'danger',
    translation: 'error.PasswordErrorException',
  },
  PasswordResetRequiredException: {
    type: 'danger',
    translation: 'error.PasswordResetRequiredException',
  },
  UserNotFoundException: {
    type: 'danger',
    translation: 'error.NotAuthorizedException',
  },
  UserLambdaValidationException: {
    type: 'danger',
    translation: 'error.UserLambdaValidationException',
  },
  connectionError: {
    type: 'danger',
    translation: 'error.noconnection',
    autoHide: false,
  },
  connectionSuccess: {
    type: 'success',
    translation: 'success.noconnection',
  },
};
