import { useLocation, Navigate } from 'react-router-dom';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { Hub } from '@aclito/shared/classes/Hub';
import { useEffect, useState } from 'react';
import { getUserData } from '@aclito/shared/util/cognitoUser';
import { currentUser } from '@aclito/shared/util/helpersApi';
import { AxiomLogger } from '@aclito/shared/classes/AxiomLogger';
import { isLoginDisabled } from '@aclito/shared/util/handleStatus';
import api from '@aclito/shared/api/api';
import { StatusData } from '@aclito/shared/api/requests/custom/status/getStatus';

import {
  ROUTE_ABOUT_USER,
  ROUTE_SIGN_IN,
  ROUTE_STATUS,
} from '@/util/constants';
import { saveContextToLocalStorage } from '@/util/context';

type RequireAuthProps = {
  children: JSX.Element;
};

const log = AxiomLogger.getLogger('router');

export const RequireAuth = ({ children }: RequireAuthProps) => {
  const location = useLocation();
  const [status, setStatus] = useState<StatusData | undefined>();
  const { route, user } = useAuthenticator((context) => [
    context.route,
    context.user,
  ]);

  useEffect(() => {
    log.info({ router: location.pathname });
  }, [location]);

  useEffect(() => {
    if (!status) {
      api.custom.status.getStatus().then((data) => setStatus(data));
    }
  }, [status]);

  useEffect(() => {
    const getData = async () => {
      if (user) {
        Hub.user = await getUserData();
        Hub.cognitoUser = await currentUser();
      }
    };
    getData();
  }, [user]);

  const getRoute = () => {
    if (route !== 'authenticated' && route !== 'idle') {
      if (route !== 'signOut') {
        saveContextToLocalStorage(location);
      }
      return <Navigate to={ROUTE_SIGN_IN} state={{ from: location }} replace />;
    }

    if (status && isLoginDisabled(status)) {
      return <Navigate to={ROUTE_STATUS} />;
    }

    if (
      user?.attributes?.['custom:Login'] === 'FIRST_TIME' &&
      location.pathname !== ROUTE_ABOUT_USER
    ) {
      return <Navigate to={ROUTE_ABOUT_USER} />;
    }
    return children;
  };

  return getRoute();
};
