import { NotificationType } from '@aclito/enums';
import { ReactElement } from 'react';

import { Icon } from '@/components';

const LocalNotificationIcons = (type: NotificationType) => {
  const icons: Record<Partial<NotificationType>, ReactElement> = {
    EVENT_LEFT: <Icon icon="personRemove" size={32} color="grey50" />,
    EVENT_MULTIPLE_LEFT: <Icon icon="personRemove" size={32} color="grey50" />,
    EVENT_LEFT_REPEATING: <Icon icon="personRemove" size={32} color="grey50" />,
    EVENT_MULTIPLE_QUEUE_LEFT: (
      <Icon icon="personRemove" size={32} color="grey50" />
    ),
    EVENT_KICK: <Icon icon="personRemove" size={32} color="grey50" />,
    EVENT_JOIN: <Icon icon="personAdd" size={32} color="grey50" />,
    EVENT_JOIN_REPEATING: <Icon icon="personAdd" size={32} color="grey50" />,
    EVENT_MULTIPLE_JOIN: <Icon icon="personAdd" size={32} color="grey50" />,
    EVENT_QUEUE_MULTIPLE_JOIN: (
      <Icon icon="personAdd" size={32} color="grey50" />
    ),
    EVENT_QUEUE_JOIN: <Icon icon="personAdd" size={32} color="grey50" />,
    EVENT_PLAYER_CONFIRMED: (
      <Icon icon="checkmarkCircle" size={32} color="grey50" />
    ),
    EVENT_MULTIPLE_PLAYER_CONFIRMED: (
      <Icon icon="checkmarkCircle" size={32} color="grey50" />
    ),
    EVENT_INVITE: <Icon icon="email" size={32} color="grey50" />,
    EVENT_CHANGE: <Icon icon="warning" size={32} color="grey50" />,
    EVENT_CHANGE_REPEATING: <Icon icon="warning" size={32} color="grey50" />,
    EVENT_DELETE: <Icon icon="delete" size={32} color="grey50" />,
    EVENT_DELETE_REPEATING: <Icon icon="delete" size={32} color="grey50" />,
    EVENT_STARTING: <Icon icon="timer" size={32} color="grey50" />,
    EVENT_QUEUE_KICK: <Icon icon="personRemove" size={32} color="grey50" />,
    EVENT_QUEUE_LEFT: <Icon icon="personRemove" size={32} color="grey50" />,
    EVENT_QUEUE_SHIFT: <Icon icon="queueShift" size={32} color="grey50" />,
    EVENT_CONFIRM: <Icon icon="timer" size={32} color="grey50" />,
    ORG_ROLE_CHANGE: <Icon icon="org" size={32} color="grey50" />,
    ORG_DELETE: <Icon icon="delete" size={32} color="grey50" />,
    ORG_JOIN: <Icon icon="personAdd" size={32} color="grey50" />,
    ORG_LEFT: <Icon icon="personRemove" size={32} color="grey50" />,
    ORG_KICK: <Icon icon="personRemove" size={32} color="grey50" />,
    FEED_CREATE: <Icon icon="feedAdd" size={32} color="grey50" />,
    FEED_UPDATE: <Icon icon="warning" size={32} color="grey50" />,
  };

  return icons[type] ?? null;
};

export default LocalNotificationIcons;
