import { createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit';

import {
  signOutAsync,
  federatedSignInAsync,
  requestNewPasswordAsync,
  signInAsync,
  singUpAsync,
  submitNewPasswordAsync,
  REDUX_AUTH_RQ_NEW_PSWD,
  REDUX_AUTH_SIGN_IN,
  REDUX_AUTH_SIGN_UP,
  REDUX_AUTH_SUBMIT_NEW_PSWD,
} from '../thunks/authThunks';
import { REDUX_RESET } from '../../util/constants';
import { AuthInitialState } from '../initialStates';
import { AppState } from '../store';

export const REDUX_FIRST_TIME = 'firstTimeLogin';
export const REDUX_AUTH = 'auth';

const SliceIsFirstTime = createSlice({
  name: REDUX_FIRST_TIME,
  initialState: false,
  reducers: {
    updateIsFirstTime: (_, action: PayloadAction<boolean>) => action.payload,
    [REDUX_RESET + REDUX_FIRST_TIME]: () => false,
  },
});

const SliceAuth = createSlice({
  name: REDUX_AUTH,
  initialState: AuthInitialState,
  reducers: {
    [REDUX_RESET + REDUX_AUTH]: () => AuthInitialState,
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      isAnyOf(
        signInAsync.pending,
        singUpAsync.pending,
        requestNewPasswordAsync.pending,
        submitNewPasswordAsync.pending,
      ),
      (state, action) => {
        switch (action.type.split('/')[0]) {
          case REDUX_AUTH_SIGN_IN:
            state.isLoadingSignIn = true;
            break;
          case REDUX_AUTH_SIGN_UP:
            state.isLoadingSignUp = true;
            break;
          case REDUX_AUTH_RQ_NEW_PSWD:
            state.isLoadingRequestPassword = true;
            break;
          case REDUX_AUTH_SUBMIT_NEW_PSWD:
            state.isLoadingSubmitPassword = true;
            break;
          default:
            break;
        }
      },
    );

    builder.addMatcher(
      isAnyOf(
        signInAsync.fulfilled,
        singUpAsync.fulfilled,
        requestNewPasswordAsync.fulfilled,
        submitNewPasswordAsync.fulfilled,
      ),
      (state, action) => {
        switch (action.type.split('/')[0]) {
          case REDUX_AUTH_SIGN_IN:
            state.isLoadingSignIn = false;
            break;
          case REDUX_AUTH_SIGN_UP:
            state.isLoadingSignUp = false;
            break;
          case REDUX_AUTH_RQ_NEW_PSWD:
            state.isLoadingRequestPassword = false;
            break;
          case REDUX_AUTH_SUBMIT_NEW_PSWD:
            state.isLoadingSubmitPassword = false;
            break;
          default:
            break;
        }
      },
    );

    builder.addMatcher(
      isAnyOf(
        signInAsync.rejected,
        singUpAsync.rejected,
        submitNewPasswordAsync.rejected,
        requestNewPasswordAsync.rejected,
      ),
      (state, action) => {
        state.isLoadingSignIn = false;
        state.isLoadingSignUp = false;
        state.isLoadingRequestPassword = false;
        state.isLoadingSubmitPassword = false;
        state.error = action?.payload?.type ?? '';
      },
    );
  },
});

export const authActions = {
  ...SliceIsFirstTime.actions,
  ...SliceAuth.actions,
  signInAsync,
  singUpAsync,
  requestNewPasswordAsync,
  submitNewPasswordAsync,
  signOutAsync,
  federatedSignInAsync,
};

export const authReducers = {
  [REDUX_FIRST_TIME]: SliceIsFirstTime.reducer,
  [REDUX_AUTH]: SliceAuth.reducer,
};

export const authSelectors = {
  firstTimeLogin: (state: AppState) => state.firstTimeLogin,
  isLoadingSignIn: (state: AppState) => state.auth.isLoadingSignIn,
  isLoadingSignUp: (state: AppState) => state.auth.isLoadingSignUp,
  isLoadingSubmitPassword: (state: AppState) =>
    state.auth.isLoadingSubmitPassword,
  isLoadingRequestPassword: (state: AppState) =>
    state.auth.isLoadingRequestPassword,
};
